import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Resizer from 'react-image-file-resizer';
import api from '../../utils/api';

const MaintenanceMode = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch existing maintenance data
  useEffect(() => {
    const fetchMaintenanceData = async () => {
      setLoading(true);
      try {
        const response = await api.get('/admin/maintainance-mode');
        if (response.data.success) {
          const data = JSON.parse(response.data.data.data_values);
          setDescription(data.description || '');
        }
      } catch (error) {
        console.error('Failed to fetch maintenance data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMaintenanceData();
  }, []);

  // Toggle form enable/disable
  const toggleForm = () => {
    setIsDisabled(!isDisabled);
  };

  // Handle Image Upload and Resize
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      Resizer.imageFileResizer(
        file,
        660,
        325,
        'JPEG',
        100,
        0,
        (uri) => {
          setImagePreview(uri);
        },
        'base64'
      );
    } else {
      alert('Unsupported file type. Please upload .png, .jpg, or .jpeg');
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    const payload = {
      data_values: JSON.stringify({ description }),
    };

    try {
      const response = await api.post('/admin/maintainance-mode', payload);
      if (response.data.success) {
        alert('Maintenance mode updated successfully!');
      } else {
        alert('Failed to update maintenance mode.');
      }
    } catch (error) {
      console.error('Error updating maintenance mode:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Maintenance Mode</h2>
        </div>
      </header>
      <div className="px-8 py-4">
        {/* Toggle button for enabling/disabling form */}
        <div className="mb-4">
          <label className="block text-gray-700 text-md font-bold mb-2">
            Status
          </label>
          <button
            onClick={toggleForm}
            className={`px-4 py-2 font-bold text-white rounded ${
              isDisabled ? 'bg-green-500' : 'bg-red-500'
            } hover:opacity-75`}
          >
            {isDisabled ? 'Enable Form' : 'Disable Form'}
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="flex flex-wrap md:flex-nowrap gap-8">
            {/* Image Upload Section */}
            <div className="mb-4 w-3/5">
              <label className="block text-gray-700 text-md font-bold mb-2">
                Upload Image
              </label>
              <input
                type="file"
                disabled={isDisabled}
                accept=".png,.jpg,.jpeg"
                onChange={handleImageUpload}
                className="border p-2 w-full"
              />
              <p className="text-gray-500 text-sm mt-1">
                Supported Files: .png, .jpg, .jpeg
              </p>
              <p className="text-gray-500 text-sm">
                Image will be resized to 660x325px
              </p>

              {imagePreview && (
                <div className="mt-4">
                  <img src={imagePreview} alt="Preview" className="border" />
                </div>
              )}
            </div>

            {/* Description Text Editor */}
            <div className="mb-4 w-full">
              <label className="block text-gray-700 text-md font-bold mb-2">
                Description
              </label>
              <ReactQuill
                value={description}
                onChange={setDescription}
                readOnly={isDisabled}
                theme="snow"
              />
            </div>
          </div>
        )}

        {/* Submit Button */}
        <div className="mt-4">
          <button
            onClick={handleSubmit}
            disabled={isDisabled}
            className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${
              isDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default MaintenanceMode;
