import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';

function PushNotificationSettings() {
  // State management for form inputs
  const [formData, setFormData] = useState({
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  });

  // State for loading status
  const [loading, setLoading] = useState(true);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchData = async () => {
    try {
      const response = await api.get('/admin/notification-push', {
        headers: { 'Content-Type': 'application/json' },
      });

      // Check if response is valid
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          console.log('Parsed data:', data);
        } else {
          console.error('Invalid content type:', contentType);
        }
      } else {
        console.error('Failed to fetch data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/admin/notification-push', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ push_notification: JSON.stringify(formData) }),
      });
      if (response.ok) {
        alert('Push Notification Settings Updated Successfully');
      } else {
        const errorDetails = await response.text();
        console.error('Failed to update settings:', errorDetails);
        alert('Failed to update settings');
      }
    } catch (error) {
      console.error('Error updating data:', error.message);
      alert('Error updating settings');
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Push Notification Settings</h2>
          <p className="text-gray-500"></p>
        </div>
      </header>

      <div className="px-4 py-4 border-l-8 border-blue-600 bg-blue-100 rounded-md mx-8">
        <p>
          If you want to send push notifications via Firebase, your system must
          be SSL certified.
        </p>
      </div>

      {loading ? (
        <div className="mx-8 my-4 p-4  border rounded-md shadow-md bg-white">
          <p>Loading data...</p>
        </div>
      ) : (
        <div className="mx-8 my-4 p-4 border rounded-md shadow-md bg-white">
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-1 md:grid-cols-3 gap-4"
          >
            {/* API Key */}
            <div>
              <label
                htmlFor="apiKey"
                className="block text-sm font-medium text-gray-700"
              >
                API Key
              </label>
              <input
                type="text"
                id="apiKey"
                name="apiKey"
                value={formData.apiKey}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter API Key"
                required
              />
            </div>

            {/* Auth Domain */}
            <div>
              <label
                htmlFor="authDomain"
                className="block text-sm font-medium text-gray-700"
              >
                Auth Domain
              </label>
              <input
                type="text"
                id="authDomain"
                name="authDomain"
                value={formData.authDomain}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Auth Domain"
                required
              />
            </div>

            {/* Project ID */}
            <div>
              <label
                htmlFor="projectId"
                className="block text-sm font-medium text-gray-700"
              >
                Project ID
              </label>
              <input
                type="text"
                id="projectId"
                name="projectId"
                value={formData.projectId}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Project ID"
                required
              />
            </div>

            {/* Storage Bucket */}
            <div>
              <label
                htmlFor="storageBucket"
                className="block text-sm font-medium text-gray-700"
              >
                Storage Bucket
              </label>
              <input
                type="text"
                id="storageBucket"
                name="storageBucket"
                value={formData.storageBucket}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Storage Bucket"
                required
              />
            </div>

            {/* Messaging Sender ID */}
            <div>
              <label
                htmlFor="messagingSenderId"
                className="block text-sm font-medium text-gray-700"
              >
                Messaging Sender ID
              </label>
              <input
                type="text"
                id="messagingSenderId"
                name="messagingSenderId"
                value={formData.messagingSenderId}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Messaging Sender ID"
                required
              />
            </div>

            {/* App ID */}
            <div>
              <label
                htmlFor="appId"
                className="block text-sm font-medium text-gray-700"
              >
                App ID
              </label>
              <input
                type="text"
                id="appId"
                name="appId"
                value={formData.appId}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter App ID"
                required
              />
            </div>

            {/* Measurement ID */}
            <div>
              <label
                htmlFor="measurementId"
                className="block text-sm font-medium text-gray-700"
              >
                Measurement ID
              </label>
              <input
                type="text"
                id="measurementId"
                name="measurementId"
                value={formData.measurementId}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Measurement ID"
                required
              />
            </div>

            {/* Submit Button */}
            <div className="md:col-span-3">
              <button
                type="submit"
                className="bg-Select_text text-white px-4 py-2 rounded-md hover:bg-Select_text2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default PushNotificationSettings;
