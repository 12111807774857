import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../utils/api'; // Assuming `api` is a pre-configured Axios instance

const RobotsTXT = () => {
  const [robotsTxt, setRobotsTxt] = useState('');

  // Fetch robots.txt content from the API
  useEffect(() => {
    const fetchRobotsTxt = async () => {
      try {
        const response = await api.get('/admin/robot-txt');
        if (response.data && response.data.data) {
          const parsedData = JSON.parse(response.data.data);
          setRobotsTxt(parsedData.Conetnet);
          toast.success('Robots.txt data retrieved successfully!', {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error('Error fetching Robots.txt:', error);
        toast.error('Failed to retrieve Robots.txt. Please try again.', {
          autoClose: 3000,
        });
      }
    };

    fetchRobotsTxt();
  }, []);

  // Submit updated robots.txt content to the API
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      robots: JSON.stringify({ Conetnet: robotsTxt }),
    };

    try {
      const response = await api.post('/admin/robot-txt', requestBody);
      if (response.data && response.data.success) {
        toast.success('Robots.txt has been updated successfully!', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error updating Robots.txt:', error);
      toast.error('Failed to update Robots.txt. Please try again.', {
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Robots TXT</h2>
        </div>
      </header>

      <div className="mt-4 gap-4 px-8">
        <h1 className="text-2xl font-bold mb-4">Insert Robots.txt</h1>
        <form onSubmit={handleSubmit}>
          <textarea
            value={robotsTxt}
            onChange={(e) => setRobotsTxt(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md mb-4 h-48 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Insert your Robots.txt content here..."
          />
          <button
            type="submit"
            className="bg-Select_text text-white px-4 py-2 rounded-md hover:bg-Select_text2"
          >
            Submit
          </button>
        </form>
      </div>

      {/* Add ToastContainer for React-Toastify */}
      <ToastContainer />
    </>
  );
};

export default RobotsTXT;
