import { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import SideBarPage from './Components/SideBarPage';
import LoginForm from './Components/LoginForm';
import Home from './Components/Home';
import Dashboard from './Components/Dashboard';
import Categories from './Components/ManageCategories/Categories';
import SubCategories from './Components/ManageCategories/SubCategories';
import Review from './Components/ManageCategories/Review';
import SaleItems from './Components/ManageSales/SaleItems';
import RefundedItems from './Components/ManageSales/RefundedItems';
import AllSales from './Components/ManageSales/AllSales';
import AllProducts from './Components/ManageProducts/AllProducts';
import ApprovedProducts from './Components/ManageProducts/ApprovedProducts';
import PendingProducts from './Components/ManageProducts/PendingProducts';
import SoftRejectedProducts from './Components/ManageProducts/SoftRejectedProducts';
import HardRejectedProducts from './Components/ManageProducts/HardRejectedProducts';
import SoftDisabledProducts from './Components/ManageProducts/SoftDisabledProducts';
import PermanentDisabledProducts from './Components/ManageProducts/PermanentDisabledProducts';
import Authors from './Components/ManageAuthor/Authors';
import AuthorLevel from './Components/ManageAuthor/AuthorLevel';
import ActiveUsers from './Components/ManageUsers/ActiveUsers';
import BannedUsers from './Components/ManageUsers/BannedUsers';
import EmailUnverifiedUsers from './Components/ManageUsers/EmailUnverifiedUsers';
import KYCUnverifiedUsers from './Components/ManageUsers/KYCUnverifiedUsers';
import KYCPendingUsers from './Components/ManageUsers/KYCPendingUsers';
import UsersWithBalance from './Components/ManageUsers/UsersWithBalance';
import AllUsers from './Components/ManageUsers/AllUsers';
import Reviews from './Components/ReviewsComments/Reviews';
import Comments from './Components/ReviewsComments/Comments';
import PendingPayments from './Components/Payments/PendingPayments';
import ApprovedPayments from './Components/Payments/ApprovedPayments';
import SuccessfulPayments from './Components/Payments/SuccessfulPayments';
import RejectedPayments from './Components/Payments/RejectedPayments';
import InitiatedPayments from './Components/Payments/InitiatedPayments';
import PendingWithdrawals from './Components/Withdrawals/PendingWithdrawals';
import ApprovedWithdrawals from './Components/Withdrawals/ApprovedWithdrawals';
import RejectedWithdrawals from './Components/Withdrawals/RejectedWithdrawals';
import PendingTickets from './Components/SupportTicket/PendingTickets';
import ClosedTickets from './Components/SupportTicket/ClosedTickets';
import AnsweredTickets from './Components/SupportTicket/AnsweredTickets';
import TransactionHistory from './Components/Report/TransactionHistory';
import LoginHistory from './Components/Report/LoginHistory';
import NotificationHistory from './Components/Report/NotificationHistory';
import Subscribers from './Components/Subscribers/Subscribers';
import Application from './Components/Extra/Application';
import Server from './Components/Extra/Server';
import Clear from './Components/Extra/Clear';
import Updates from './Components/Extra/Updates';
import ReportAndRequest from './Components/ReportAndRequest/ReportAndRequest';
import AllPayments from './Components/Payments/AllPayments';
import AllWithdrawals from './Components/Withdrawals/AllWithdrawals';
import SendNotification from './Components/ManageUsers/SendNotification';
import AllTickets from './Components/SupportTicket/AllTickets';
import SystemSettings from './Components/SystemSettings/SystemSettings';
import GeneralSetting from './Components/SystemSettings/GeneralSetting';
import LogoandFavicon from './Components/SystemSettings/LogoandFavicon';
import SystemConfiguration from './Components/SystemSettings/SystemConfiguration';
import ManageFees from './Components/SystemSettings/ManageFees';
import LicenseInformation from './Components/SystemSettings/LicenseInformation';
import FTP from './Components/SystemSettings/StoreConfigure/FTP';
import DigitalOcean from './Components/SystemSettings/StoreConfigure/DigitalOcean';
import Wasabi from './Components/SystemSettings/StoreConfigure/Wasabi';
import AuthorInformation from './Components/SystemSettings/AuthorInformation';
import GlobalTemplate from './Components/SystemSettings/NotificationSetting/GlobalTemplate';
import EmailSetting from './Components/SystemSettings/NotificationSetting/EmailSetting';
import SMSSettings from './Components/SystemSettings/NotificationSetting/SMSSettings';
import PushNotificationSettings from './Components/SystemSettings/NotificationSetting/PushNotificationSettings';
import NotificationTemplates from './Components/SystemSettings/NotificationSetting/NotificationTemplates';
import AutomaticGateways from './Components/SystemSettings/PaymentGateways/AutomaticGateways';
import ManualGateways from './Components/SystemSettings/PaymentGateways/ManualGateways';
import WithdrawalMethods from './Components/SystemSettings/WithdrawalMethods';
import SCOConfiguration from './Components/SystemSettings/SCOConfiguration';
import ManageFrontend from './Components/SystemSettings/ManageFrontend';
import ManagePages from './Components/SystemSettings/ManagePages';
import MaintenanceMode from './Components/SystemSettings/MaintenanceMode';
import GDPRCookie from './Components/SystemSettings/GDPRCookie';
import CustomCSS from './Components/SystemSettings/CustomCSS';
import SitemapXML from './Components/SystemSettings/SitemapXML';
import RobotsTXT from './Components/SystemSettings/RobotsTXT';
import BenefitSection from './Components/SystemSettings/ManageFrontend/BenefitSection';
import BanSection from './Components/SystemSettings/ManageFrontend/BanSection';
import BecomeAuthor from './Components/SystemSettings/ManageFrontend/BecomeAuthor';
import BannerSection from './Components/SystemSettings/ManageFrontend/BannerSection';
import CollectionDefinition from './Components/SystemSettings/ManageFrontend/CollectionDefinition';
import ContactUs from './Components/SystemSettings/ManageFrontend/ContactUs';
import CTASection from './Components/SystemSettings/ManageFrontend/CTASection';
import FAQSection from './Components/SystemSettings/ManageFrontend/FAQSection';
import FeatureSection from './Components/SystemSettings/ManageFrontend/FeatureSection';
import FeaturedProductSectionPage from './Components/SystemSettings/ManageFrontend/FeaturedProductSectionPage';
import FooterSection from './Components/SystemSettings/ManageFrontend/FooterSection';
import KYCContent from './Components/SystemSettings/ManageFrontend/KYCContent';
import LatestProductSection from './Components/SystemSettings/ManageFrontend/LatestProductSection';
import LoginSection from './Components/SystemSettings/ManageFrontend/LoginSection';
import MarketPlaceSection from './Components/SystemSettings/ManageFrontend/MarketPlaceSection';
import PolicyPages from './Components/SystemSettings/ManageFrontend/PolicyPages';
import RegisterSection from './Components/SystemSettings/ManageFrontend/RegisterSection';
import RegistrationDisable from './Components/SystemSettings/ManageFrontend/RegistrationDisable';
import SocialIcons from './Components/SystemSettings/ManageFrontend/SocialIcons';
import UploadTerm from './Components/SystemSettings/ManageFrontend/UploadTerm';
import WeeklyBestSelling from './Components/SystemSettings/ManageFrontend/WeeklyBestSelling';
import MobileUnverifiedUsers from './Components/ManageUsers/MobileUnverifiedUsers';
import ProductDetails from './Components/ManageProducts/ProductDetails';
import SaleDetails from './Components/ManageSales/SaleDetails';
import AuthorDetails from './Components/ManageAuthor/AuthorDetails';
import NotificationsSentToUsername from './Components/ManageUsers/NotificationsSentToUsername';
import PendingPayment from './Dashboardcards/Payment/PendingPayment';
import PaymentDetail from './Components/Payments/PaymentDetail';
import WithdrawalsDetails from './Components/Withdrawals/WithdrawalsDetails';
import ReplyTicket from './Components/SupportTicket/ReplyTicket';

function App() {
  // State to manage token presence
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check if token is available in localStorage
    const storedToken = localStorage.getItem('access_token');
    setToken(storedToken);
  }, []);

  // If no token, redirect to login page
  if (!token) {
    return <LoginForm />;
  }

  return (
    <div className="flex">
      <SideBarPage />
      <div className="w-full">
        <Header />
        <div>
          <Routes>
            {/* <Route path="/" element={<Dashboard />} /> */}
            <Route path="/dashboard" element={<Dashboard />} />

            {/* Manage Categories */}
            <Route path="/managecategories" element={<Dashboard />} />
            <Route
              path="/managecategories/categories"
              element={<Categories />}
            />
            <Route
              path="/managecategories/sub-categories"
              element={<SubCategories />}
            />
            <Route path="/managecategories/review" element={<Review />} />

            {/* Manage Sales */}
            <Route path="/managesales/salesitems" element={<SaleItems />} />
            <Route
              path="/managesales/refundeditems"
              element={<RefundedItems />}
            />
            <Route path="/managesales/allsales" element={<AllSales />} />
            <Route path="/managesales/saledetails" element={<SaleDetails />} />

            {/* Manage Products */}
            <Route
              path="/manageproducts/productdetails"
              element={<ProductDetails />}
            />
            <Route
              path="/manageproducts/allproducts"
              element={<AllProducts />}
            />
            <Route
              path="/manageproducts/approvedproducts"
              element={<ApprovedProducts />}
            />
            <Route
              path="/manageproducts/pendingproducts"
              element={<PendingProducts />}
            />
            <Route
              path="/manageproducts/softrejectedproducts"
              element={<SoftRejectedProducts />}
            />
            <Route
              path="/manageproducts/hardrejectedproducts"
              element={<HardRejectedProducts />}
            />
            <Route
              path="/manageproducts/softdisabledproducts"
              element={<SoftDisabledProducts />}
            />
            <Route
              path="/manageproducts/permanentdisabledproducts"
              element={<PermanentDisabledProducts />}
            />

            {/* Manage Author */}
            <Route path="/manageauthors/authors" element={<Authors />} />
            <Route
              path="/manageauthors/authorlevel"
              element={<AuthorLevel />}
            />
            <Route
              path="/manageauthors/authordetails"
              element={<AuthorDetails />}
            />

            {/* Manage Users */}
            <Route path="/manageusers/activeusers" element={<ActiveUsers />} />
            <Route path="/manageusers/bannedusers" element={<BannedUsers />} />
            <Route
              path="/manageusers/emailunverifiedusers"
              element={<EmailUnverifiedUsers />}
            />
            <Route
              path="/manageusers/mobileUnverifiedUsers"
              element={<MobileUnverifiedUsers />}
            />
            <Route
              path="/manageusers/kyc-unverifiedusers"
              element={<KYCUnverifiedUsers />}
            />
            <Route
              path="/manageusers/kyc-pendingusers"
              element={<KYCPendingUsers />}
            />
            <Route
              path="/manageusers/userswithbalance"
              element={<UsersWithBalance />}
            />
            <Route
              path="/manageusers/send-notification"
              element={<SendNotification />}
            />
            <Route path="/manageusers/allusers" element={<AllUsers />} />
            <Route
              path="/manageusers/notificationsendto/username"
              element={<NotificationsSentToUsername />}
            />

            {/* Reviews & Comments */}
            <Route path="/reviewscomments/reviews" element={<Reviews />} />
            <Route path="/reviewscomments/comments" element={<Comments />} />

            {/* Payments */}
            <Route
              path="/payments/pendingpayments"
              element={<PendingPayments />}
            />
            <Route
              path="/payments/approvedpayments"
              element={<ApprovedPayments />}
            />
            <Route
              path="/payments/successfulpayments"
              element={<SuccessfulPayments />}
            />
            <Route
              path="/payments/rejectedpayments"
              element={<RejectedPayments />}
            />
            <Route
              path="/payments/initiatedpayments"
              element={<InitiatedPayments />}
            />
            <Route path="/payments/allpayments" element={<AllPayments />} />
            <Route
              path="/payments/paymentdetail/:paymentId"
              element={<PaymentDetail />}
            />

            {/* withdrawals */}
            <Route
              path="/withdrawals/pendingwithdrawals"
              element={<PendingWithdrawals />}
            />
            <Route
              path="/withdrawals/approvedwithdrawals"
              element={<ApprovedWithdrawals />}
            />
            <Route
              path="/withdrawals/rejectedwithdrawals"
              element={<RejectedWithdrawals />}
            />
            <Route
              path="/withdrawals/allwithdrawals"
              element={<AllWithdrawals />}
            />
            <Route
              path="/withdrawals/withdrawaldetails"
              element={<WithdrawalsDetails />}
            />

            {/* support Ticket */}
            <Route
              path="/supportticket/pendingtickets"
              element={<PendingTickets />}
            />
            <Route
              path="/supportticket/closedtickets"
              element={<ClosedTickets />}
            />
            <Route
              path="/supportticket/answeredtickets"
              element={<AnsweredTickets />}
            />
            <Route path="/supportticket/alltickets" element={<AllTickets />} />
            <Route
              path="/supportticket/ticketdetails"
              element={<ReplyTicket />}
            />

            {/* report */}
            <Route
              path="/report/transactionhistory"
              element={<TransactionHistory />}
            />
            <Route path="/report/loginhistory" element={<LoginHistory />} />
            <Route
              path="/report/notificationhistory"
              element={<NotificationHistory />}
            />

            {/* Subscribers */}
            <Route path="/subscribers" element={<Subscribers />} />

            {/* Extra */}
            <Route path="/extra/application" element={<Application />} />
            <Route path="/extra/server" element={<Server />} />
            <Route path="/extra/clear" element={<Clear />} />
            <Route path="/extra/updates" element={<Updates />} />

            {/* Report and Request */}
            <Route path="/reportandrequest" element={<ReportAndRequest />} />

            {/*System Settings */}
            <Route
              path="/systemsettings/generalsetting"
              element={<GeneralSetting />}
            />
            <Route
              path="/systemsettings/LogoandFavicon"
              element={<LogoandFavicon />}
            />
            <Route
              path="/systemsettings/systemconfiguration"
              element={<SystemConfiguration />}
            />
            <Route path="/systemsettings/managefees" element={<ManageFees />} />
            <Route
              path="/systemsettings/licenseinformation"
              element={<LicenseInformation />}
            />
            {/* StoreConfigure */}
            <Route
              path="/systemsettings/storeconfigure/ftp"
              element={<FTP />}
            />
            <Route
              path="/systemsettings/storeconfigure/wasabi"
              element={<Wasabi />}
            />
            <Route
              path="/systemsettings/storeconfigure/digitalocean"
              element={<DigitalOcean />}
            />
            {/* ********** */}
            <Route
              path="/systemsettings/authorinformation"
              element={<AuthorInformation />}
            />
            {/* notificationsetting */}
            <Route
              path="/systemsettings/notificationsetting/globaltemplate"
              element={<GlobalTemplate />}
            />
            <Route
              path="/systemsettings/notificationsetting/emailsetting"
              element={<EmailSetting />}
            />
            <Route
              path="/systemsettings/notificationsetting/smssettings"
              element={<SMSSettings />}
            />
            <Route
              path="/systemsettings/notificationsetting/pushnotificationsettings"
              element={<PushNotificationSettings />}
            />
            <Route
              path="/systemsettings/notificationsetting/notificationtemplates"
              element={<NotificationTemplates />}
            />

            {/* paymentgateways */}
            <Route
              path="/systemsettings/paymentgateways/automaticgateways"
              element={<AutomaticGateways />}
            />
            <Route
              path="/systemsettings/paymentgateways/manualgateways"
              element={<ManualGateways />}
            />
            {/* ************* */}
            <Route
              path="/systemsettings/withdrawalmethods"
              element={<WithdrawalMethods />}
            />
            <Route
              path="/systemsettings/scoconfiguration"
              element={<SCOConfiguration />}
            />
            <Route
              path="/systemsettings/managefrontend"
              element={<ManageFrontend />}
            />
            {/* ##################### */}
            <Route
              path="/systemsettings/managefrontend/benefitSection"
              element={<BenefitSection />}
            />
            <Route
              path="/systemsettings/managefrontend/banSection"
              element={<BanSection />}
            />
            <Route
              path="/systemsettings/managefrontend/bannerSection"
              element={<BannerSection />}
            />
            <Route
              path="/systemsettings/managefrontend/becomeAuthor"
              element={<BecomeAuthor />}
            />
            <Route
              path="/systemsettings/managefrontend/collectiondefinition"
              element={<CollectionDefinition />}
            />
            <Route
              path="/systemsettings/managefrontend/contactUs"
              element={<ContactUs />}
            />
            <Route
              path="/systemsettings/managefrontend/ctaSection"
              element={<CTASection />}
            />
            <Route
              path="/systemsettings/managefrontend/faqSection"
              element={<FAQSection />}
            />
            <Route
              path="/systemsettings/managefrontend/featureSection"
              element={<FeatureSection />}
            />
            <Route
              path="/systemsettings/managefrontend/featuredProductSection"
              element={<FeaturedProductSectionPage />}
            />
            <Route
              path="/systemsettings/managefrontend/footerSection"
              element={<FooterSection />}
            />
            <Route
              path="/systemsettings/managefrontend/kycContent"
              element={<KYCContent />}
            />
            <Route
              path="/systemsettings/managefrontend/latestProductSection"
              element={<LatestProductSection />}
            />
            <Route
              path="/systemsettings/managefrontend/loginSection"
              element={<LoginSection />}
            />
            <Route
              path="/systemsettings/managefrontend/marketPlaceSection"
              element={<MarketPlaceSection />}
            />
            <Route
              path="/systemsettings/managefrontend/policyPages"
              element={<PolicyPages />}
            />
            <Route
              path="/systemsettings/managefrontend/registerSection"
              element={<RegisterSection />}
            />
            <Route
              path="/systemsettings/managefrontend/registrationDisable"
              element={<RegistrationDisable />}
            />
            <Route
              path="/systemsettings/managefrontend/socialIcons"
              element={<SocialIcons />}
            />
            <Route
              path="/systemsettings/managefrontend/uploadTerm"
              element={<UploadTerm />}
            />
            <Route
              path="/systemsettings/managefrontend/weeklyBestSelling"
              element={<WeeklyBestSelling />}
            />

            {/* ##################### */}

            <Route
              path="/systemsettings/managepages"
              element={<ManagePages />}
            />
            <Route
              path="/systemsettings/maintenancemode"
              element={<MaintenanceMode />}
            />
            <Route
              path="/systemsettings/gdpr-cookie"
              element={<GDPRCookie />}
            />
            <Route path="/systemsettings/custom-css" element={<CustomCSS />} />
            <Route
              path="/systemsettings/sitemap-xml"
              element={<SitemapXML />}
            />
            <Route path="/systemsettings/robots-txt" element={<RobotsTXT />} />
          </Routes>
        </div>
      </div>
      {/* <Routes>  */}
      {/* <Route path="/" element={<LoginForm />} />
      <Route path="/home" element={<Home />} /> */}

      {/* </Routes> */}
    </div>
  );
}

export default App;
