import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../utils/api';

const FTP = () => {
  const [formData, setFormData] = useState({
    ftpRootPath: '',
    host: '',
    userName: '',
    password: '',
    port: '',
    rootFolder: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Fetch data from the API
  const fetchStorageConfig = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/admin/storage-configure');
      if (response.data.success) {
        const ftpConfig = JSON.parse(response.data.data[0].ftp);
        setFormData({
          ftpRootPath: ftpConfig.domain || '',
          host: ftpConfig.host || '',
          userName: ftpConfig.username || '',
          password: ftpConfig.password || '',
          port: ftpConfig.port || '',
          rootFolder: ftpConfig.root || '',
        });
      }
    } catch (error) {
      console.error('Error fetching storage configuration:', error);
      toast.error('Failed to fetch configuration.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        ftp: {
          domain: formData.ftpRootPath,
          host: formData.host,
          username: formData.userName,
          password: formData.password,
          port: formData.port,
          root: formData.rootFolder,
        },
        wasabi: {
          driver_name: 'Wasabi',
          api_key: 'wasabi-dummy-api-key',
          secret_key: 'wasabi-dummy-secret-key',
          region: 'us-east-1',
          bucket_name: 'wasabi-bucket-name',
          endpoint: 'https://s3.wasabisys.com',
        },
        digital_ocean: {
          driver_name: 'DigitalOcean',
          api_key: 'digitalocean-dummy-api-key',
          secret_key: 'digitalocean-dummy-secret-key',
          region: 'nyc3',
          bucket_name: 'digitalocean-bucket-name',
          endpoint: 'https://nyc3.digitaloceanspaces.com',
        },
      };
      const response = await api.post('/admin/storage-configure', body);
      if (response.data.success) {
        toast.success('Configuration updated successfully!');
      } else {
        throw new Error('Failed to update configuration.');
      }
    } catch (error) {
      console.error('Error updating storage configuration:', error);
      toast.error('Failed to update configuration.');
    }
  };

  useEffect(() => {
    fetchStorageConfig();
  }, []);

  return (
    <>
      <ToastContainer />
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">FTP Configuration</h2>
        </div>
      </header>
      <div className="py-2">
        {isLoading ? (
          <p className="text-center text-gray-500">Loading...</p>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="bg-white border m-4 rounded px-8 pt-6 pb-8 mb-4 grid md:grid-cols-3 grid-cols-1 gap-4"
          >
            {/* FTP Root Path */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="ftpRootPath"
              >
                FTP Hosting Root Path (Please Enter With http protocol)
              </label>
              <input
                id="ftpRootPath"
                name="ftpRootPath"
                type="text"
                value={formData.ftpRootPath}
                onChange={handleChange}
                placeholder="http://example.com"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Host */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="host"
              >
                Host
              </label>
              <input
                id="host"
                name="host"
                type="text"
                value={formData.host}
                onChange={handleChange}
                placeholder="ftp.example.com"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Username */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="userName"
              >
                UserName
              </label>
              <input
                id="userName"
                name="userName"
                type="text"
                value={formData.userName}
                onChange={handleChange}
                placeholder="Your Username"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Password */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Your Password"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Port */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="port"
              >
                Port
              </label>
              <input
                id="port"
                name="port"
                type="number"
                value={formData.port}
                onChange={handleChange}
                placeholder="21"
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Root Folder */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="rootFolder"
              >
                Root Folder
              </label>
              <input
                id="rootFolder"
                name="rootFolder"
                type="text"
                value={formData.rootFolder}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Submit Button */}
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default FTP;
