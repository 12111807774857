import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../utils/api';

function LicenseInformation() {
  const [personalLicenseItems, setPersonalLicenseItems] = useState([]);
  const [commercialLicenseItems, setCommercialLicenseItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [licenseType, setLicenseType] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  // Fetch license data from API
  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await api.get('/admin/license');
        const result = response.data;

        if (result.success) {
          const { personal_license_features, commercial_license_features } = result.data[0];
          setPersonalLicenseItems(JSON.parse(personal_license_features));
          setCommercialLicenseItems(JSON.parse(commercial_license_features));
        } else {
          console.error('Error fetching license data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching license data:', error);
      }
    };

    fetchLicenseData();
  }, []);

  const handleAddItem = () => {
    const newItem = { name: newItemName };
    if (licenseType === 'personal') {
      setPersonalLicenseItems([...personalLicenseItems, newItem.name]);
    } else {
      setCommercialLicenseItems([...commercialLicenseItems, newItem.name]);
    }
    setShowModal(false); // Close the modal after adding the item
    setNewItemName(''); // Clear the input field
  };

  const openModal = (type) => {
    setLicenseType(type);
    setShowModal(true); // Open the modal
  };

  const handleRemoveItem = (index, licenseType) => {
    if (licenseType === 'personal') {
      const updatedItems = [...personalLicenseItems];
      updatedItems.splice(index, 1);
      setPersonalLicenseItems(updatedItems);
    } else {
      const updatedItems = [...commercialLicenseItems];
      updatedItems.splice(index, 1);
      setCommercialLicenseItems(updatedItems);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const payload = {
        personal_license_features: JSON.stringify(personalLicenseItems),
        commercial_license_features: JSON.stringify(commercialLicenseItems),
      };

      const response = await api.post('/admin/license', payload);
      const result = response.data;

      if (result.success) {
        toast.success('License information saved successfully!');
      } else {
        toast.error('Failed to save license information.');
      }
    } catch (error) {
      console.error('Error saving license information:', error);
      toast.error('Error saving license information.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">License Information</h2>
          <p className="text-gray-500">View and manage software license information.</p>
        </div>
      </header>
      <div className="flex flex-col justify-center items-center mt-4 gap-4 px-8 w-full">
        <div className="border rounded-lg shadow-md md:w-1/2 w-full">
          <div className='flex flex-wrap gap-4 items-center justify-between md:px-4 md:py-2 px-2 py-1 bg-gray-200'>
            <h2 className="text-xl font-bold text-gray-900">Personal License</h2>
            <button
              className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded"
              onClick={() => openModal('personal')}
            >
              + Add New
            </button>
          </div>
          <div className="mt-4 px-4 py-2">
            {personalLicenseItems.map((item, index) => (
              <div key={index} className="flex bg-purple-200 justify-between items-center py-2 px-4 rounded-md border-b mb-4">
                <span>{item}</span>
                <button
                  className="bg-red-500 hover:bg-red-700 px-3 py-2 rounded-md text-white font-bold"
                  onClick={() => handleRemoveItem(index, 'personal')}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className='px-4 py-2 border-t'>
            <button
              className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-6 rounded"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>

        <div className="border rounded-lg shadow-md md:w-1/2 w-full">
          <div className='flex flex-wrap gap-4 items-center justify-between px-4 py-2 bg-gray-200'>
            <h2 className="text-xl font-bold text-gray-900">Commercial License</h2>
            <button
              className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded"
              onClick={() => openModal('commercial')}
            >
              + Add New
            </button>
          </div>
          <div className="mt-4 px-4 py-2">
            {commercialLicenseItems.map((item, index) => (
              <div key={index} className="flex bg-purple-200 justify-between items-center py-2 px-4 rounded-md border-b mb-4">
                <span>{item}</span>
                <button
                  className="bg-red-500 hover:bg-red-700 px-3 py-2 rounded-md text-white font-bold"
                  onClick={() => handleRemoveItem(index, 'commercial')}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className='px-4 py-2 border-t'>
            <button
              className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-6 rounded"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Add New Item to {licenseType === 'personal' ? 'Personal' : 'Commercial'} License</h2>
            <input
              type="text"
              className="border p-2 rounded w-full mb-4"
              placeholder="Enter item name"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-Select_text hover:bg-Select_text2 text-white px-4 py-2 rounded"
                onClick={handleAddItem}
              >
                Add Item
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default LicenseInformation;
