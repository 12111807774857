import React, { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { PiEyeSlash } from 'react-icons/pi';
import { MdOutlineEdit } from 'react-icons/md';
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../utils/api';
function Categories() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCategory, setNewCategory] = useState({
    name: '',
    menuOrder: '',
    status: 'Inactive',
  });
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 10; // Maximum number of rows per page
  const totalPages = Math.ceil(filteredData.length / rowsPerPage); // Calculate total pages

  // Get current page data
  const currentPageData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  // Get All Data  api
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/categories');
        if (response.data.status === 'success') {
          const formattedData = response.data.data.map((item) => ({
            selected: false,
            categorieImg:
              'https://cdn-icons-png.flaticon.com/128/2721/2721652.png', // Placeholder image
            categorieName: item.category_name,
            status: item.category_status === 1 ? 'Active' : 'Inactive',
            featured: 'Yes',
            categoryId: item.cat_id, // Updated key
          }));
          setData(formattedData);
          setFilteredData(formattedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle search
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    setFilteredData(
      data.filter((item) =>
        item.categorieName.toLowerCase().includes(lowercasedQuery)
      )
    );
    setCurrentPage(1); // Reset to first page when search query changes
  }, [searchQuery, data]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setFilteredData(
      filteredData.map((row) => ({ ...row, selected: newSelectAll }))
    ); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = filteredData.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setFilteredData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected)); // If all checkboxes are selected, set selectAll to true
  };

  const toggleStatus = () => {
    setNewCategory((prev) => ({
      ...prev,
      status: prev.status === 'Active' ? 'Inactive' : 'Active',
    }));
  };

  const handleAddCategory = async () => {
    try {
      const payload = {
        category_name: newCategory.name,
        menu_order: newCategory.menuOrder,
        category_status: newCategory.status === 'Active' ? '1' : '0',
      };

      // API call to add category
      const response = await api.post('/admin/categories', payload);

      if (response.data.success) {
        toast.success('Category added successfully!', { autoClose: 3000 });
        setNewCategory({ name: '', menuOrder: '', status: 'Inactive' });
      } else {
        console.error('Error adding category:', response.data.message);
        toast.error('Failed to add category. Please try again.', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('API error:', error);
      toast.error('An error occurred while adding the category.', {
        autoClose: 3000,
      });
    }
  };

  const openEditModal = async (categoryId) => {
    console.log('Selected Category ID:', categoryId); // For debugging

    try {
      // Fetch category details by ID
      const response = await api.get(`/admin/categories/${categoryId}`); // Fixed template literal
      const category = response.data.category; // Updated key path for your API
      console.log(response);
      console.log(response.data.category);

      // Set the fetched data in the modal
      setSelectedCategoryIndex(categoryId);
      setNewCategory({
        name: category.category_name,
        menuOrder: category.menu_order,
        status: category.category_status === 1 ? 'Active' : 'Inactive',
      });

      setShowEditModal(true);
    } catch (error) {
      console.error('Error fetching category details:', error);
    }
  };

  const handleEditCategory = async () => {
    try {
      const payload = {
        category_name: newCategory.name,
        menu_order: newCategory.menuOrder,
        category_status: newCategory.status === 'Active' ? 1 : 0,
      };

      // Update category by ID
      const response = await api.put(
        `/admin/categories/${selectedCategoryIndex}`,
        payload
      );

      if (response.data.success) {
        // Update the local state
        const updatedData = data.map((item) =>
          item.id === selectedCategoryIndex
            ? {
                ...item,
                name: newCategory.name,
                menuOrder: newCategory.menuOrder,
                status: newCategory.status,
              }
            : item
        );
        setData(updatedData);
        setShowEditModal(false);
        toast.success('Category updated successfully!', { autoClose: 3000 });
      } else {
        console.error('Error updating category:', response.data.message);
        toast.error('Failed to update category. Please try again.', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('API error:', error);
      toast.error('An error occurred while updating the category.', {
        autoClose: 3000,
      });
    }
  };

  const handleDeactivateCategory = async () => {
    try {
      const categoryId = data[selectedCategoryIndex].id; // Assuming the category ID is available
      const response = await api.delete(`/admin/categories/${categoryId}`);

      if (response.data.success) {
        // Update the local state to mark the category as inactive
        const updatedData = [...data];
        updatedData[selectedCategoryIndex].status = 'Inactive';
        setData(updatedData);
        setNewCategory({ ...newCategory, status: 'Inactive' }); // Update newCategory state
        alert('Category deactivated successfully!');
      } else {
        alert('Failed to deactivate category. Please try again.');
      }
    } catch (error) {
      console.error('API error:', error);
      alert('An error occurred while deactivating the category.');
    }
  };

  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

  const handleAlertButtonClick = () => {
    setShowAlert(true); // Show the alert when the button is clicked
  };

  const handleConfirm = () => {
    //  Execute your unfeature action here
    console.log('Category unfeatured!');
    setShowAlert(false);
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  return (
    <>
      <header className="flex flex-wrap justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Categories</h2>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowModal(true)}
            className="bg-Select_text hover:bg-Select_text2 text-white font-semibold h-min p-3 text-sm rounded-lg"
          >
            <span className="flex items-center gap-2">
              <IoMdAddCircleOutline /> Add New Categories
            </span>
          </button>
        </div>
      </header>

      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b ">
              <th className="border-b">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>
              <th className="px-4 py-4 text-start text-black font-bold uppercase text-sm ">
                Name
              </th>
              <th className="px-4 py-4 text-start text-black font-bold uppercase text-sm">
                Status
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Featured
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Action
              </th>
            </thead>
            <tbody>
              {currentPageData && currentPageData.length > 0 ? (
                currentPageData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100 border-b">
                    <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={() => handleRowCheckboxChange(index)}
                        className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                      />
                    </td>
                    <td className="px-2 py-3 text-md text-start">
                      <div className="flex items-center gap-4">
                        <img
                          src={item.categorieImg}
                          alt="Product"
                          className="w-8 h-8 inline-block rounded-md"
                        />
                        <div className="">
                          <span className="">{item.categorieName}</span>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <span className="px-5 py-3 rounded-lg bg-green-100 border border-green-600 text-green-600">
                        {item.status}
                      </span>
                    </td>
                    <td className="px-2 py-3 text-md text-start pl-4">
                      <span className="px-5 py-3 rounded-lg bg-Select_text border border-yellow-600 text-white">
                        {item.featured}
                      </span>
                    </td>
                    <td className="px-2 py-3 text-sm text-start pl-4">
                      <div className="flex gap-2 justify-start flex-wrap">
                        <button
                          onClick={() => openEditModal(item.categoryId)}
                          className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                        >
                          <span className="flex items-center gap-2">
                            <MdOutlineEdit />{' '}
                          </span>
                        </button>
                        <button
                          className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white`}
                          onClick={handleAlertButtonClick}
                        >
                          <PiEyeSlash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center py-10">
                    <div className="flex flex-col items-center">
                      {/* Add your animation here, e.g., a spinner */}
                      <svg
                        className="animate-spin h-8 w-8 text-gray-600 mb-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                      </svg>
                      <p className="text-gray-500">No data available</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
        <p className="text-gray-600">
          Showing {currentPageData.length} of {filteredData.length} Results
        </p>
        <div className="flex gap-1">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
          >
            <FaAngleLeft />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
          >
            <FaAngleRight />
          </button>
        </div>
      </div>

      {/* Add Category Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className="flex items-center justify-between py-2">
              <h2 className="text-lg font-bold">Add New Category</h2>
              <button onClick={() => setShowModal(false)} className="font-bold">
                X
              </button>
            </div>
            <label htmlFor="newCategory" className="font-semibold">
              Name
            </label>
            <input
              type="text"
              htmlFor="newCategory"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            />
            <label htmlFor="menuOrder" className="font-semibold block mb-2">
              Menu Order
            </label>
            <input
              type="text"
              id="menuOrder"
              value={newCategory.menuOrder}
              onChange={(e) =>
                setNewCategory({ ...newCategory, menuOrder: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            />
            <div className="mb-4">
              <h1 className="font-semibold mb-2">Status</h1>
              <button
                onClick={toggleStatus}
                className={`px-4 py-2 rounded-md ${
                  newCategory.status === 'Active'
                    ? 'bg-green-500'
                    : 'bg-gray-500'
                } text-white`}
              >
                {newCategory.status}
              </button>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleAddCategory}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Add Category
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Category Modal */}

      {showEditModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className="flex items-center justify-between py-2">
              <h2 className="text-lg font-bold mb-4">Edit Category</h2>
              <button
                onClick={() => setShowEditModal(false)}
                className="font-bold"
              >
                X
              </button>
            </div>
            <label htmlFor="Category" className="font-semibold">
              Name
            </label>
            <input
              type="text"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            />
            <label htmlFor="MenuOrder" className="font-semibold">
              Menu Order
            </label>
            <input
              type="number"
              value={newCategory.menuOrder}
              onChange={(e) =>
                setNewCategory({ ...newCategory, menuOrder: e.target.value })
              }
              className="w-full mb-4 p-2 border rounded"
            />
            <h1 className="font-semibold">Status</h1>
            <button
              onClick={() =>
                setNewCategory({
                  ...newCategory,
                  status:
                    newCategory.status === 'Active' ? 'Inactive' : 'Active',
                })
              }
              className={`px-4 py-2 rounded-md mb-4 ${
                newCategory.status === 'Active'
                  ? 'bg-red-500 hover:bg-red-600'
                  : 'bg-gray-500 hover:bg-gray-600'
              } text-white`}
            >
              {newCategory.status === 'Active' ? 'Deactivate' : 'Inactive'}
            </button>

            <div className="flex justify-end space-x-4">
              <button
                onClick={handleEditCategory}
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {showAlert && (
        <div className="fixed top-0 inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 mt-10">
            <div className="flex justify-between items-center py-2">
              <h2 className="text-lg font-bold">Confirmation Alert!</h2>
              <button className="font-bold" onClick={handleCancel}>
                X
              </button>
            </div>
            <p className="border-b border-t py-2">
              Are you sure to unfeature this category?
            </p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-Select_text2 hover:bg-Select_text text-white font-bold py-2 px-4 rounded-lg mr-2"
                onClick={handleCancel}
              >
                No
              </button>
              <button
                className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-lg"
                onClick={handleConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Categories;
