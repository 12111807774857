import React, { useState, useEffect } from 'react';
import api from '../../utils/api'; // Adjust the path to your API utility

function ManageFees() {
  const [personalLicenseFee, setPersonalLicenseFee] = useState('');
  const [commercialLicenseFee, setCommercialLicenseFee] = useState('');
  const [extendedFee, setExtendedFee] = useState('');

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Fetch initial data from API
  useEffect(() => {
    const fetchFees = async () => {
      try {
        const response = await api.get('/admin/manage-fees');
        const data = response?.data?.data;
        console.log('data', data.data);

        if (data) {
          setPersonalLicenseFee(data.personal_buyer_fee || '');
          setCommercialLicenseFee(data.commercial_buyer_fee || '');
          setExtendedFee(data.twelve_month_extended_fee || '');
        } else {
          console.error('Data object is empty or undefined');
          alert('Failed to fetch fee data. Please try again later.');
        }
      } catch (error) {
        console.error('Error fetching fees:', error);
        alert('An error occurred while fetching fee data.');
      } finally {
        setLoading(false);
      }
    };

    fetchFees();
  }, []);

  // Handle form submission to update data
  const handleSubmit = async () => {
    setSaving(true);
    try {
      const requestData = {
        personal_buyer_fee: parseFloat(personalLicenseFee),
        commercial_buyer_fee: parseFloat(commercialLicenseFee),
        twelve_month_extended_fee: parseFloat(extendedFee),
      };

      const response = await api.post('/admin/manage-fees', requestData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response?.data?.success) {
        alert('Fees updated successfully!');
      } else {
        console.error('Response from API:', response);
        alert('Failed to update fees. Please try again.');
      }
    } catch (error) {
      console.error('Error updating fees:', error);
      alert('An error occurred while updating fees.');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Loading fee data...</p>
      </div>
    );
  }

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Manage Product Fees</h2>
          <p className="text-gray-500">
            Manage and configure all fee-related settings.
          </p>
        </div>
      </header>
      <div className="mx-auto p-4">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-8 px-6">
          <div>
            <label
              htmlFor="personal-license-fee"
              className="block text-gray-700 font-bold mb-2"
            >
              Buyer Fee (Personal License) *
            </label>
            <div className="flex items-center border bg-gray-200 rounded-lg">
              <span className="text-gray-900 font-bold px-3">$</span>
              <input
                type="number"
                id="personal-license-fee"
                className="shadow appearance-none border-r border-l w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={personalLicenseFee}
                onChange={(e) => setPersonalLicenseFee(e.target.value)}
              />
              <span className="text-gray-900 font-bold px-3">USD</span>
            </div>
          </div>
          <div>
            <label
              htmlFor="commercial-license-fee"
              className="block text-gray-700 font-bold mb-2"
            >
              Buyer Fee (Commercial License) *
            </label>
            <div className="flex items-center border bg-gray-200 rounded-lg">
              <span className="text-gray-900 font-bold px-3">$</span>
              <input
                type="number"
                id="commercial-license-fee"
                className="shadow appearance-none border-r border-l w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={commercialLicenseFee}
                onChange={(e) => setCommercialLicenseFee(e.target.value)}
              />
              <span className="text-gray-900 font-bold px-3">USD</span>
            </div>
          </div>
          <div>
            <label
              htmlFor="extended-fee"
              className="block text-gray-700 font-bold mb-2"
            >
              12 Month Extended Fee
            </label>
            <div className="flex items-center border bg-gray-200 rounded-lg">
              <span className="text-gray-900 font-bold px-3">$</span>
              <input
                type="number"
                id="extended-fee"
                className="shadow appearance-none border-r border-l w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={extendedFee}
                onChange={(e) => setExtendedFee(e.target.value)}
              />
              <span className="text-gray-900 font-bold px-3">USD</span>
            </div>
          </div>
        </div>

        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline mt-4 ms-6"
          onClick={handleSubmit}
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Submit'}
        </button>
      </div>
    </>
  );
}

export default ManageFees;
