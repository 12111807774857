import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import api from '../../utils/api';

const GDPRCookie = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch data from the API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get('/admin/gdpr-cookie');
        const { data_values } = response.data.data;
        const parsedData = JSON.parse(data_values);

        setShortDescription(parsedData.short_desc);
        setDescription(parsedData.description);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Toggle form enable/disable
  const toggleForm = () => {
    setIsDisabled(!isDisabled);
  };

  // Submit updated data to the API
  const handleSubmit = async () => {
    try {
      const updatedData = {
        short_desc: shortDescription,
        description,
      };

      const response = await api.post('/admin/gdpr-cookie', {
        data_keys: 'cookie.data',
        data_values: JSON.stringify(updatedData),
      });

      if (response.data.success) {
        alert('Data updated successfully!');
      } else {
        alert('Failed to update data.');
      }
    } catch (error) {
      console.error('Error updating data:', error);
      alert('An error occurred while updating data.');
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-4">GDPR Cookie Consent</h2>

      {/* Loading Indicator */}
      {loading ? (
        <p className="text-gray-500">Loading data...</p>
      ) : (
        <>
          {/* Toggle button for enabling/disabling form */}
          <div className="mb-4">
            <button
              onClick={toggleForm}
              className={`px-4 py-2 font-bold text-white rounded ${
                isDisabled ? 'bg-green-500' : 'bg-red-500'
              } hover:opacity-75`}
            >
              {isDisabled ? 'Enable Form' : 'Disable Form'}
            </button>
          </div>

          {/* Short Description Text Area */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Short Description
            </label>
            <textarea
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
              disabled={isDisabled}
              className="border p-2 w-full"
              rows="4"
            />
          </div>

          {/* Description Text Editor */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Description
            </label>
            <ReactQuill
              value={description}
              onChange={setDescription}
              readOnly={isDisabled}
              theme="snow"
            />
          </div>

          {/* Submit Button */}
          <div className="mt-4">
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isDisabled}
              className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${
                isDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default GDPRCookie;
