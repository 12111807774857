import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../../utils/api';

function SMSSettings() {
  const [sendMethod, setSendMethod] = useState('');
  const [formData, setFormData] = useState({
    apiKey: '',
    username: '',
    password: '',
    apiSecret: '',
  });
  const [allConfig, setAllConfig] = useState({}); // Store all configurations

  const fetchSMSConfig = async () => {
    try {
      const response = await api.get('/admin/notification-sms');
      const smsConfig = JSON.parse(response.data.data[0]?.sms_config || '{}');
      setAllConfig(smsConfig);
      setSendMethod(smsConfig.name || '');
      populateFormFields(smsConfig.name, smsConfig);
    } catch (error) {
      console.error('Error fetching SMS notification data:', error);
    }
  };

  const populateFormFields = (method, config) => {
    const methodConfig = config[method] || {};
    setFormData({
      apiKey: methodConfig.api_key || '',
      username: methodConfig.username || '',
      password: methodConfig.password || '',
      apiSecret: methodConfig.api_secret || '',
    });
  };

  useEffect(() => {
    fetchSMSConfig();
  }, []);

  const handleMethodChange = (e) => {
    const method = e.target.value;
    setSendMethod(method);
    populateFormFields(method, allConfig);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedConfig = {
      ...allConfig,
      name: sendMethod,
      [sendMethod]: {
        api_key: formData.apiKey,
        username: formData.username,
        password: formData.password,
        api_secret: formData.apiSecret,
      },
    };

    try {
      const postBody = {
        sms_config: JSON.stringify(updatedConfig),
      };
      await api.post('/admin/notification-sms', postBody);
      console.log('SMS configuration updated successfully');
      fetchSMSConfig(); // Re-fetch the updated configuration
    } catch (error) {
      console.error('Error updating SMS configuration:', error);
    }
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">SMS Notification Settings</h2>
        </div>
      </header>

      <div className="mx-8 my-4 p-4 border rounded-md shadow-md bg-white">
        <h2 className="text-2xl font-bold mb-4">SMS Send Method</h2>

        <div className="mb-4">
          <label
            htmlFor="sendMethod"
            className="text-sm font-medium text-gray-700"
          >
            SMS Send Method
          </label>
          <select
            id="sendMethod"
            name="sendMethod"
            value={sendMethod}
            onChange={handleMethodChange}
            className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
          >
            <option value="">Select Method</option>
            <option value="clickatell">Clickatell</option>
            <option value="infobip">Infobip</option>
            <option value="message_bird">Message Bird</option>
            <option value="nexmo">Nexmo</option>
          </select>
        </div>

        {sendMethod && (
          <div>
            <h1 className="font-bold text-lg mt-2">
              {sendMethod} Configuration
            </h1>
            {['apiKey', 'username', 'password', 'apiSecret'].map((field) => (
              <div key={field} className="mb-4">
                <label
                  htmlFor={field}
                  className="text-sm font-medium text-gray-700 capitalize"
                >
                  {field.replace('api', 'API')}{' '}
                  {field === 'apiSecret' && 'Secret'}
                </label>
                <input
                  type={
                    field.includes('password') || field === 'apiSecret'
                      ? 'password'
                      : 'text'
                  }
                  id={field}
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                  className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                  placeholder={`Enter ${sendMethod} ${field.replace(
                    'api',
                    'API'
                  )}`}
                  required
                />
              </div>
            ))}
          </div>
        )}

        <button
          type="submit"
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default SMSSettings;
