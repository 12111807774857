import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from 'react-icons/io';
import api from '../../utils/api';
import { CgScreen } from 'react-icons/cg';
import { PiEyesBold } from 'react-icons/pi';

function AllProducts() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.get('/admin/items');
        const itemData = response.data?.itemData?.data || [];
        const transformedData = itemData.map((item) => ({
          selected: false,
          id: item.item_id || 'N/A',
          name: item.item_name || 'N/A',
          category: item.item_featured === 'yes' ? 'Featured' : 'Regular',
          thumbnail: item.item_thumbnail || '',
          uploadDate: 'N/A', // Add uploadDate if available in API response
          author: item.username || 'Unknown',
          subCategory: 'N/A', // Placeholder
          price: 'N/A', // Placeholder
          stock: 'N/A', // Placeholder
          status: item.item_status === 1 ? 'Available' : 'Unavailable',
          rating: 'N/A', // Placeholder
        }));
        setData(transformedData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  // Handle checkbox selection
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  // Filter data based on search query
  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.status.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentPageData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleProductDetails = (productId) => {
    if (productId) {
      navigate(`/products/productdetail/${productId}`);
    } else {
      console.error('Product ID is undefined or invalid!');
    }
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <h2 className="text-xl font-bold">All Products</h2>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md flex items-center gap-2"
          onClick={() => navigate('/products/add')}
        >
          <IoMdAddCircleOutline className="text-2xl" />
          Add Product
        </button>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="checkbox h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Image
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Name
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Upload Date
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Author
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Category
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Sub Category
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Price
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Stock
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Status
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Rating
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100 border-b">
                    <td className="px-4 py-3 text-center">
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={() => handleRowCheckboxChange(index)}
                        className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                      />
                    </td>
                    <td className="px-4 py-3 text-center">
                      <img
                        src={item.thumbnail}
                        alt={item.name}
                        className="w-16 h-16 object-cover rounded"
                      />
                    </td>
                 

                    <td className="px-4 py-3 text-md text-start pl-4">{item.name}</td>
                    <td className="px-4 py-3 text-md text-start pl-4">{item.uploadDate}</td>
                    <td className="px-4 py-3 text-md text-start pl-4">{item.author}</td>
                    <td className="px-4 py-3 text-md text-start pl-4">{item.category}</td>
                    <td className="px-4 py-3 text-md text-start pl-4">{item.subCategory}</td>
                    <td className="px-4 py-3 text-md text-start pl-4">{item.price}</td>
                    <td className="px-4 py-3 text-md text-start pl-4">{item.stock}</td>
                    <td className="px-4 py-3 text-md text-start pl-4">
                      
                      {item.item_featured}

                    </td>
                    <td className="px-4 py-3 text-md text-start pl-4">{item.rating}</td>

                   

                    <td className="px-2 py-3 text-sm text-start pl-4">
                      <div className="flex gap-2 flex-wrap">
                        <button
                          onClick={() => handleProductDetails(item.id)}
                          className="{px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white}"
                        >
                          <CgScreen />
                        </button>
                        <button
                          // onClick={handleAlertButtonClick}
                          className="{px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white}"
                        >
                          <PiEyesBold />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center py-4 text-gray-500">
                    No products available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between px-8 py-2 border">
          <p>
            Showing {currentPageData.length} of {filteredData.length} Results
          </p>
          <div className="flex gap-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded"
            >
              <FaAngleLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-4 py-2 rounded ${
                  currentPage === index + 1
                    ? 'bg-orange-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded"
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllProducts;
