import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import api from '../../utils/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SCOConfiguration = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [metaDescription, setMetaDescription] = useState('');
  const [socialTitle, setSocialTitle] = useState('');
  const [socialDescription, setSocialDescription] = useState('');
  const [imageId, setImageId] = useState('');
  const [seoId, setSeoId] = useState(null);
  const [loading, setLoading] = useState(false);

  const keywordOptions = [
    { value: 'digital product', label: 'Digital Product' },
    { value: 'digital items', label: 'Digital Items' },
    { value: 'buy', label: 'Buy' },
    { value: 'sell', label: 'Sell' },
    { value: 'market place', label: 'Market Place' },
  ];

  useEffect(() => {
    const fetchSEOData = async () => {
      setLoading(true);
      try {
        const response = await api.get('/admin/seo-manager');
        if (response.data.success) {
          const data = JSON.parse(response.data.data.data_values);
          setMetaKeywords(
            data.keywords.map((kw) => ({ value: kw, label: kw }))
          );
          setMetaDescription(data.description);
          setSocialTitle(data.social_title);
          setSocialDescription(data.social_description);
          setImagePreview(`/path/to/images/${data.image}`);
          setImageId(data.image);
          setSeoId(response.data.data.id);
        }
      } catch (error) {
        toast.error('Failed to fetch SEO data.');
        console.error('Failed to fetch SEO data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSEOData();
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setImageId(file.name); // Assuming you'll upload the file and set its ID later
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const keywords = metaKeywords.map((kw) => kw.value);

    const payload = {
      data_values: JSON.stringify({
        seo_image: imageId,
        keywords,
        description: metaDescription,
        social_title: socialTitle,
        social_description: socialDescription,
        image: imageId,
      }),
    };

    try {
      const response = await api.post('/admin/seo-manager', payload);
      if (response.data.success) {
        toast.success('SEO Configuration Updated Successfully!');
      } else {
        toast.error('Failed to update SEO configuration.');
      }
    } catch (error) {
      console.error('Error updating SEO configuration:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  const isFormValid =
    metaKeywords.length > 0 &&
    metaDescription &&
    socialTitle &&
    socialDescription;

  return (
    <div className="px-8 py-4">
      <h2 className="text-2xl font-bold mb-4">SEO Configuration</h2>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row gap-8">
            <div className="w-full md:w-1/3">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Upload Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="border p-2 w-full"
              />
              {imagePreview && (
                <div className="mt-4">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="w-full border"
                  />
                </div>
              )}
            </div>

            <div className="w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Meta Keywords
              </label>
              <Select
                isMulti
                value={metaKeywords}
                onChange={setMetaKeywords}
                options={keywordOptions}
                placeholder="Select Meta Keywords"
                className="mb-4"
              />

              <label className="block text-gray-700 text-sm font-bold mb-2">
                Meta Description
              </label>
              <textarea
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
                rows="3"
                className="border p-2 w-full mb-4"
              />

              <label className="block text-gray-700 text-sm font-bold mb-2">
                Social Title
              </label>
              <input
                type="text"
                value={socialTitle}
                onChange={(e) => setSocialTitle(e.target.value)}
                className="border p-2 w-full mb-4"
              />

              <label className="block text-gray-700 text-sm font-bold mb-2">
                Social Description
              </label>
              <textarea
                value={socialDescription}
                onChange={(e) => setSocialDescription(e.target.value)}
                rows="3"
                className="border p-2 w-full"
              />

              <button
                type="submit"
                disabled={!isFormValid}
                className={`bg-blue-500 text-white py-2 px-4 mt-4 rounded ${
                  !isFormValid ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      )}

      <ToastContainer />
    </div>
  );
};

export default SCOConfiguration;
