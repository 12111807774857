import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wasabi = () => {
  const [formData, setFormData] = useState({
    driverName: '',
    accessKey: '',
    secretKey: '',
    region: '',
    bucketName: '',
    endpointUrl: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Fetch data from the API
  const fetchStorageConfig = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/admin/storage-configure');
      if (response.data.success && response.data.data.length > 0) {
        const wasabiConfig = JSON.parse(response.data.data[0].wasabi);
        setFormData({
          driverName: wasabiConfig.driver_name || '',
          accessKey: wasabiConfig.api_key || '',
          secretKey: wasabiConfig.secret_key || '',
          region: wasabiConfig.region || '',
          bucketName: wasabiConfig.bucket_name || '',
          endpointUrl: wasabiConfig.endpoint || '',
        });
      } else {
        toast.warn('No configuration data found.');
      }
    } catch (error) {
      console.error('Error fetching storage configuration:', error);
      toast.error('Failed to fetch configuration. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const body = {
        ftp: {
          domain: 'http://codeplus.brandeducer.store/',
          host: '192.168.1.1',
          username: 'ftp_user',
          password: 'ftp_password123',
          port: '21',
          root: '/var/www/html',
        },
        wasabi: {
          driver_name: formData.driverName,
          api_key: formData.accessKey,
          secret_key: formData.secretKey,
          region: formData.region,
          bucket_name: formData.bucketName,
          endpoint: formData.endpointUrl,
        },
        digital_ocean: {
          driver_name: 'DigitalOcean',
          api_key: 'digitalocean-dummy-api-key',
          secret_key: 'digitalocean-dummy-secret-key',
          region: 'nyc3',
          bucket_name: 'digitalocean-bucket-name',
          endpoint: 'https://nyc3.digitaloceanspaces.com',
        },
      };

      const response = await api.post('/admin/storage-configure', body);
      if (response.data.success) {
        toast.success('Configuration updated successfully!');
      } else {
        throw new Error(
          response.data.message || 'Failed to update configuration.'
        );
      }
    } catch (error) {
      console.error('Error updating storage configuration:', error);
      toast.error('Failed to update configuration. Please check your input.');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchStorageConfig();
  }, []);

  return (
    <>
      <ToastContainer />
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Wasabi Configuration</h2>
          <p className="text-gray-500">
            Configure your Wasabi storage settings below.
          </p>
        </div>
      </header>
      <div className="py-2">
        {isLoading ? (
          <p className="text-center text-gray-500">Loading...</p>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="bg-white border m-4 rounded px-8 pt-6 pb-8 mb-4 grid md:grid-cols-3 grid-cols-1 gap-4"
          >
            {Object.keys(formData).map((field) => (
              <div className="mb-4" key={field}>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor={field}
                >
                  {field
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, (str) => str.toUpperCase())}
                </label>
                <input
                  id={field}
                  name={field}
                  type={field === 'secretKey' ? 'password' : 'text'}
                  placeholder={`Enter ${field}`}
                  className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData[field]}
                  onChange={handleChange}
                />
              </div>
            ))}
            <div className="flex items-center justify-between col-span-full">
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default Wasabi;
