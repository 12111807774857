import React, { useState, useEffect } from 'react';
import api from '../../utils/api';

function SystemConfiguration() {
  const [moduleStates, setModuleStates] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const formatKeyName = (key) => key.replace(/([A-Z])/g, ' $1').trim();

  useEffect(() => {
    const fetchConfiguration = async () => {
      setLoading(true);
      try {
        const response = await api.get('/admin/system-configuration');
        const data = response.data;

        if (data.success) {
          const apiData = data.data[0]; // Assuming the first object is relevant
          setModuleStates({
            userRegistration: !!apiData.user_registration,
            forceSSL: !!apiData.force_ssl,
            agreePolicy: !!apiData.agree_policy,
            forceSecurePassword: !!apiData.secure_password,
            kycVerification: !!apiData.kyc,
            emailVerification: !!apiData.email_verification,
            emailNotification: !!apiData.email_notification,
            mobileVerification: !!apiData.mobile_verification,
            smsNotification: !!apiData.smsNotification,
            pushNotification: !!apiData.push_notification,
            languageOption: !!apiData.language_notification,
          });
        }
      } catch (error) {
        console.error('Error fetching configuration:', error);
        alert('Failed to load configuration. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchConfiguration();
  }, []);

  const handleModuleToggle = (moduleName) => {
    setModuleStates((prevState) => ({
      ...prevState,
      [moduleName]: !prevState[moduleName],
    }));
  };

  const handleSubmit = async () => {
    setSaving(true);
    const requestData = {
      user_registration: moduleStates.userRegistration ? 1 : 0,
      force_ssl: moduleStates.forceSSL ? 1 : 0,
      agree_policy: moduleStates.agreePolicy ? 1 : 0,
      secure_password: moduleStates.forceSecurePassword ? 1 : 0,
      kyc: moduleStates.kycVerification ? 1 : 0,
      email_verification: moduleStates.emailVerification ? 1 : 0,
      email_notification: moduleStates.emailNotification ? 1 : 0,
      mobile_verification: moduleStates.mobileVerification ? 1 : 0,
      smsNotification: moduleStates.smsNotification ? 1 : 0,
      push_notification: moduleStates.pushNotification ? 1 : 0,
      language_notification: moduleStates.languageOption ? 1 : 0,
    };

    try {
      const response = await api.post(
        '/admin/system-configuration',
        requestData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data.success) {
        alert('Configuration updated successfully!');
      } else {
        console.error('Failed to update configuration:', response.data.message);
        alert('Failed to update configuration. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting configuration:', error);
      alert('An error occurred while saving the configuration.');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Loading configuration...</p>
      </div>
    );
  }

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">System Configuration</h2>
          <p className="text-gray-500">
            Control all of the basic modules of the system.
          </p>
        </div>
      </header>
      <div className="mx-auto mt-2 px-8 py-4">
        <div className="grid grid-cols-1">
          {Object.keys(moduleStates).map((key) => (
            <div
              key={key}
              className="bg-white flex flex-wrap justify-between items-center gap-8 border border-b-0 px-6 py-4"
            >
              <div>
                <h2 className="text-lg font-bold mb-1">{formatKeyName(key)}</h2>
                <p className="text-gray-700">
                  Description for {formatKeyName(key)} module.{' '}
                  {/* Replace with a real description */}
                </p>
              </div>
              <button
                className={`px-6 py-2 h-min rounded-md ${
                  moduleStates[key]
                    ? 'bg-green-500 text-white'
                    : 'bg-red-500 text-white'
                }`}
                onClick={() => handleModuleToggle(key)}
                aria-label={`Toggle ${formatKeyName(key)}`}
              >
                {moduleStates[key] ? 'Enabled' : 'Disabled'}
              </button>
            </div>
          ))}
        </div>
        <div>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={handleSubmit}
            disabled={saving}
          >
            {saving ? 'Saving...' : 'Submit'}
          </button>
        </div>
      </div>
    </>
  );
}

export default SystemConfiguration;
