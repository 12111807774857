import React, { useState, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import api from '../../../utils/api';

const BenefitSection = () => {
  const [rotateText, setRotateText] = useState('');
  console.log(rotateText);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [showModal, setShowModal] = useState(false); // Modal state

  useEffect(() => {
    api
      .get('/admin/manage-frontend')
      .then((result) => {
        if (result.success && Array.isArray(result.data)) {
          console.log('API Response:', result.data);

          const contentData = result.data.find(
            (item) => item.data_keys === 'additional_benefit.content' // Adjust key
          );
          console.log(contentData);

          if (contentData) {
            const parsedValues = JSON.parse(contentData.data_values);
            console.log('Parsed Values:', parsedValues);

            // Update state based on parsed data
            setRotateText(parsedValues.rotate_text || '');
            setTitle(parsedValues.title || '');
            setSubtitle(parsedValues.subtitle || '');
          } else {
            console.warn('No matching data found for "seo.data"');
          }
        } else {
          console.error('API response not as expected:', result);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // Handle form submission to update data
  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      rotate_text: rotateText,
      title,
      subtitle,
    };

    api
      .post('/admin/manage-frontend', {
        data_keys: 'additional_benefit.content',
        data_values: JSON.stringify(payload),
      })
      .then((response) => {
        console.log('Submit Response:', response.data);
        if (response.data.success) {
          alert('Data updated successfully!');
        }
      })
      .catch((error) => console.error('Error updating data:', error));
  };

  // Modal Handlers
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleModalSubmit = (event) => {
    event.preventDefault();
    setShowModal(false); // Close modal and update state
  };

  return (
    <>
      <div className="px-8 py-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold mb-4">Benefit Section</h2>
          <button
            onClick={openModal}
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            <MdAdd /> Add
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="rotateText"
              className="block text-gray-700 font-bold mb-2"
            >
              Rotate Text
            </label>
            <input
              id="rotateText"
              type="text"
              value={rotateText}
              onChange={(e) => setRotateText(e.target.value)}
              className="w-full border rounded px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-gray-700 font-bold mb-2"
            >
              Title
            </label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full border rounded px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="subtitle"
              className="block text-gray-700 font-bold mb-2"
            >
              Subtitle
            </label>
            <textarea
              id="subtitle"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              className="w-full border rounded px-4 py-2"
            />
          </div>
          <button
            type="submit"
            className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Save Changes
          </button>
        </form>

        {/* Modal */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-md w-1/3">
              <h3 className="text-xl font-bold mb-4">Edit Benefit Section</h3>
              <form onSubmit={handleModalSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="modalRotateText"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Rotate Text
                  </label>
                  <input
                    id="modalRotateText"
                    type="text"
                    value={rotateText}
                    onChange={(e) => setRotateText(e.target.value)}
                    className="w-full border rounded px-4 py-2"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="modalTitle"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Title
                  </label>
                  <input
                    id="modalTitle"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full border rounded px-4 py-2"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="modalSubtitle"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Subtitle
                  </label>
                  <textarea
                    id="modalSubtitle"
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                    className="w-full border rounded px-4 py-2"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BenefitSection;
