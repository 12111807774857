import React, { useState, useEffect } from 'react';
import api from '../../../utils/api'; // Assuming you are using an API utility like Axios
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function GlobalTemplate() {
  const [selectedContent, setSelectedContent] = useState('emailTemplate');

  const handleButtonClick = (template) => {
    setSelectedContent(template);
  };

  // Email Template State
  const [formEmailTemplateData, setFormEmailTemplateData] = useState({
    fromName: '',
    fromEmail: '',
    emailBody: '',
  });

  // Fetch existing data on component mount
  useEffect(() => {
    const fetchExistingData = async () => {
      try {
        const response = await api.get('/admin/notification-global');
        if (response?.data?.success && response?.data?.data?.length > 0) {
          const existingData = response.data.data[0];
          setFormEmailTemplateData({
            fromName: 'ViserAdmin2', // Default if not present
            fromEmail: existingData.email_from || '',
            emailBody: existingData.email_template || '',
          });
        } else {
          throw new Error('No data found');
        }
      } catch (error) {
        toast.error('Failed to load existing data.');
        console.error('Error fetching data:', error);
      }
    };

    fetchExistingData();
  }, []);

  const handleEmailTemplateInputChange = (e) => {
    const { name, value } = e.target;
    setFormEmailTemplateData({
      ...formEmailTemplateData,
      [name]: value,
    });
  };

  // API Call
  const submitEmailTemplate = async () => {
    const emailPayload = {
      email_from: formEmailTemplateData.fromEmail,
      email_template: formEmailTemplateData.emailBody,
      sms_from: 'ViserAdmin2', // Default value
      sms_body: 'hi {{fullname}} ({{username}}), {{message}}', // Default value
    };

    try {
      const response = await api.post(
        '/admin/notification-global',
        emailPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response?.data?.success) {
        toast.success('Email template submitted successfully!');
      } else {
        throw new Error(
          response?.data?.message || 'Failed to submit email template'
        );
      }
    } catch (error) {
      toast.error('Failed to submit email template. Please try again.');
      console.error('Error:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedContent === 'emailTemplate') {
      submitEmailTemplate();
    }
    // Add logic for SMS and Push Notifications as needed
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Global Notification Settings </h2>
          <p className="text-gray-500"></p>
        </div>
      </header>
      <div className="my-4 mx-12 rounded-md px-6 py-2 border">
        <ToastContainer />
        {selectedContent === 'emailTemplate' && (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            {/* Email Sent From - Name */}
            <div className="flex flex-col">
              <label
                htmlFor="fromName"
                className="text-sm font-medium text-gray-700"
              >
                Email Sent From - Name
              </label>
              <input
                type="text"
                id="fromName"
                name="fromName"
                value={formEmailTemplateData.fromName}
                onChange={handleEmailTemplateInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your name"
                required
              />
            </div>

            {/* Email Sent From - Email */}
            <div className="flex flex-col">
              <label
                htmlFor="fromEmail"
                className="text-sm font-medium text-gray-700"
              >
                Email Sent From - Email
              </label>
              <input
                type="email"
                id="fromEmail"
                name="fromEmail"
                value={formEmailTemplateData.fromEmail}
                onChange={handleEmailTemplateInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
                required
              />
            </div>

            {/* Email Body */}
            <div className="flex flex-col">
              <label
                htmlFor="emailBody"
                className="text-sm font-medium text-gray-700"
              >
                Email Body
              </label>
              <textarea
                id="emailBody"
                name="emailBody"
                value={formEmailTemplateData.emailBody}
                onChange={handleEmailTemplateInputChange}
                rows="5"
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your message"
                required
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-min bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </>
  );
}

export default GlobalTemplate;
