import React, { useEffect, useState } from 'react';
import api from '../../utils/api'; // Adjust the import path as per your project structure
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomCSS = () => {
  const [css, setCss] = useState('');

  // Fetch Custom CSS from the API
  useEffect(() => {
    const fetchCustomCSS = async () => {
      try {
        const response = await api.get('/admin/custom-css');
        if (response.data && response.data.success && response.data.data) {
          setCss(JSON.parse(response.data.data).Conetnet);
          toast.success('CSS data retrieved successfully.');
        } else {
          throw new Error('Failed to retrieve CSS data.');
        }
      } catch (error) {
        console.error('Error fetching custom CSS:', error);
        toast.error('Failed to fetch custom CSS. Please try again.');
      }
    };

    fetchCustomCSS();
  }, []);

  const handleChange = (e) => {
    setCss(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      site_custom_css: JSON.stringify({ Conetnet: css }),
    };

    try {
      const response = await api.post('/admin/custom-css', requestBody);
      if (response.data && response.data.success) {
        toast.success('Custom CSS has been successfully updated!');
      } else {
        throw new Error('Failed to update CSS.');
      }
    } catch (error) {
      console.error('Error updating custom CSS:', error);
      toast.error('Failed to update custom CSS. Please try again.');
    }
  };

  // Function to calculate line numbers based on textarea value
  const getLineNumbers = () => {
    return css
      .split('\n')
      .map((_, index) => index + 1)
      .join('\n');
  };

  return (
    <>
      <ToastContainer />
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Custom CSS</h2>
        </div>
      </header>
      <div className="bg-gray-50 mx-8 px-4 py-2 mt-2 border rounded-md">
        <p className="mb-2">
          From this page, you can add/update CSS for the user interface.
          Changing content on this page requires programming knowledge.
        </p>
        <p>
          Please do not change/edit/add anything without having proper knowledge
          of it. The website may misbehave due to any mistakes you make.
        </p>
      </div>
      <div className="mt-4 gap-4 px-8">
        <h1 className="text-xl font-bold mb-4">Write Custom CSS</h1>
        <form onSubmit={handleSubmit} className="relative">
          <div className="flex">
            {/* Line Numbers */}
            <div
              className="bg-black text-gray-400 rounded-l-lg p-2 text-right select-none"
              style={{ minWidth: '50px' }}
            >
              <pre>{getLineNumbers()}</pre>
            </div>

            {/* CSS Editor */}
            <textarea
              value={css}
              onChange={handleChange}
              className="w-full min-h-96 p-2 bg-black text-white border-none focus:outline-none rounded-r-lg resize-none"
              placeholder="Insert your Custom CSS here..."
            />
          </div>

          <button
            type="submit"
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default CustomCSS;
