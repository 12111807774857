import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../../utils/api';

function EmailSetting() {
  const [sendMethod, setSendMethod] = useState('');
  const [formData, setFormData] = useState({
    apiPublicKey: '',
    apiSecretKey: '',
    appKey: '',
    host: '',
    port: '',
    encryption: '',
    username: '',
    password: '',
  });
  const [initialData, setInitialData] = useState(null);

  // Fetch data from the API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/notification-email');
        if (response.data.success) {
          const mailConfig = JSON.parse(response.data.data[0].mail_config);
          setSendMethod(mailConfig.name);
          setFormData({
            apiPublicKey: '',
            apiSecretKey: '',
            appKey: '',
            host: mailConfig.host || '',
            port: mailConfig.port || '',
            encryption: mailConfig.enc || '',
            username: mailConfig.username || '',
            password: mailConfig.password || '',
          });
          setInitialData(response.data.data[0]);
        }
      } catch (error) {
        console.error('Error fetching email settings:', error);
      }
    };

    fetchData();
  }, []);

  const handleMethodChange = (e) => {
    setSendMethod(e.target.value);
    setFormData({
      apiPublicKey: '',
      apiSecretKey: '',
      appKey: '',
      host: '',
      port: '',
      encryption: '',
      username: '',
      password: '',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the mail_config payload
    const mailConfig = {
      name: sendMethod,
      host: formData.host,
      port: formData.port,
      enc: formData.encryption,
      username: formData.username,
      password: formData.password,
    };

    const payload = {
      mail_config: JSON.stringify(mailConfig),
    };

    try {
      const response = await api.post('/admin/notification-email', payload);
      if (response.data.success) {
        alert('Email settings updated successfully!');
      } else {
        alert('Failed to update email settings.');
      }
    } catch (error) {
      console.error('Error updating email settings:', error);
      alert('An error occurred while updating email settings.');
    }
  };

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Email Notification Settings </h2>
        </div>
      </header>

      <div className="mx-8 my-4 p-4 border rounded-md shadow-md bg-white">
        <h2 className="text-xl border-b pb-2 font-bold mb-4">
          Email Send Method
        </h2>

        {/* Dropdown for Email Send Method */}
        <div className="mb-4">
          <label
            htmlFor="sendMethod"
            className="text-sm font-medium text-gray-700"
          >
            Email Send Method
          </label>
          <select
            id="sendMethod"
            name="sendMethod"
            value={sendMethod}
            onChange={handleMethodChange}
            className="mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
          >
            <option value="">Select Method</option>
            <option value="phpMail">PHP Mail</option>
            <option value="smtp">SMTP</option>
            <option value="sendGrid">SendGrid API</option>
            <option value="mailjet">Mailjet API</option>
          </select>
        </div>

        {/* Conditional Forms Based on Send Method */}
        {sendMethod === 'mailjet' && (
          <div className="flex flex-col gap-4 mb-4">
            <h1 className="font-bold text-lg mt-2">
              Mailjet API Configuration
            </h1>
            <div>
              <label
                htmlFor="apiPublicKey"
                className="text-sm font-medium text-gray-700"
              >
                API Public Key
              </label>
              <input
                type="text"
                id="apiPublicKey"
                name="apiPublicKey"
                value={formData.apiPublicKey}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Mailjet API Public Key"
              />
            </div>

            <div>
              <label
                htmlFor="apiSecretKey"
                className="text-sm font-medium text-gray-700"
              >
                API Secret Key
              </label>
              <input
                type="text"
                id="apiSecretKey"
                name="apiSecretKey"
                value={formData.apiSecretKey}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Mailjet API Secret Key"
              />
            </div>
          </div>
        )}

        {sendMethod === 'sendGrid' && (
          <div className="mb-4">
            <h1 className="font-bold text-lg mt-2">
              SendGrid API Configuration
            </h1>
            <label
              htmlFor="appKey"
              className="text-sm font-medium text-gray-700"
            >
              App Key
            </label>
            <input
              type="text"
              id="appKey"
              name="appKey"
              value={formData.appKey}
              onChange={handleInputChange}
              className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
              placeholder="Enter SendGrid App Key"
            />
          </div>
        )}

        {sendMethod === 'smtp' && (
          <div className="flex flex-col gap-4 mb-4">
            <h1 className="font-bold text-lg mt-2">SMTP Configuration</h1>
            <div>
              <label
                htmlFor="host"
                className="text-sm font-medium text-gray-700"
              >
                Host
              </label>
              <input
                type="text"
                id="host"
                name="host"
                value={formData.host}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter SMTP Host"
              />
            </div>

            <div>
              <label
                htmlFor="port"
                className="text-sm font-medium text-gray-700"
              >
                Port
              </label>
              <input
                type="text"
                id="port"
                name="port"
                value={formData.port}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter SMTP Port"
              />
            </div>

            <div>
              <label
                htmlFor="encryption"
                className="text-sm font-medium text-gray-700"
              >
                Encryption
              </label>
              <input
                type="text"
                id="encryption"
                name="encryption"
                value={formData.encryption}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter Encryption Type (e.g., SSL/TLS)"
              />
            </div>

            <div>
              <label
                htmlFor="username"
                className="text-sm font-medium text-gray-700"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter SMTP Username"
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="mt-1 px-3 py-2 border border-gray-300 rounded-md w-full"
                placeholder="Enter SMTP Password"
              />
            </div>
          </div>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          onClick={handleSubmit}
          className="bg-Select_text hover:bg-Select_text2 text-white font-bold py-2 px-4 rounded-md "
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default EmailSetting;
