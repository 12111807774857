import React, { useState, useEffect } from 'react';
import { FaCircleInfo } from "react-icons/fa6";
import api from '../../utils/api'; // Assuming you have an API utility for making requests

function Updates() {
  const [appVersion, setAppVersion] = useState(''); // State to store the fetched version
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to handle any errors

  useEffect(() => {
    const fetchAppVersion = async () => {
      try {
        const response = await api.get('/admin/app-version'); // Replace with your API utility call
        if (response.data.success) {
          setAppVersion(response.data.data.version);
        } else {
          setError('Failed to fetch app version');
        }
      } catch (err) {
        setError('An error occurred while fetching the app version.');
        console.error('API error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAppVersion();
  }, []);

  return (
    <>
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">System Updates</h2>
          <p className="text-gray-500"></p>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-3/5">
        <div className="bg-gray-100 flex items-center justify-center">
          <div className="bg-white border border-red-600 rounded-lg shadow-md p-8">
            <div className="text-center mb-6">
              {/* Display loading spinner or app version */}
              {loading ? (
                <p className="text-xl text-gray-500">Loading...</p>
              ) : error ? (
                <p className="text-xl text-red-500">{error}</p>
              ) : (
                <>
                  <h2 className="text-7xl font-bold text-orange-500">
                    {appVersion}
                  </h2>
                  <p className="text-3xl font-bold text-orange-500">Current Version</p>
                </>
              )}
            </div>
            <div className="flex gap-2">
              <span className='text-indigo-500 text-lg'><FaCircleInfo /></span>
              <p className="ml-2 text-gray-600">
                <strong> You are currently using the latest version of the system. </strong>We are committed to continuous
                improvement and are actively developing the next version. Stay tuned for exciting new
                features and enhancements to be released soon!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Updates;
