import React, { useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa6';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdOutlineEdit } from 'react-icons/md';
import { FaList } from 'react-icons/fa';
import api from '../../utils/api';

function SubCategories() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [newCategory, setNewCategory] = useState({
    cat_id: null, // Store selected category ID
    name: '', // Category name
    subCategory: '', // Subcategory name
    order: '', // Subcategory order
    status: 'Inactive', // Subcategory status
  });

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const rowsPerPage = 30; // Maximum number of rows per page
  const totalPages = Math.ceil(filteredData.length / rowsPerPage); // Calculate total pages

  // Get current page data
  const currentPageData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  useEffect(() => {
    // Fetch categories from the API
    const fetchData = async () => {
      try {
        const response = await api.get('/sub-category');
        if (response.data.success) {
          const formattedData = response.data.data.map((item) => ({
            selected: false,
            categorieImg:
              'https://cdn-icons-png.flaticon.com/128/2721/2721652.png', // Placeholder image
            categorieName: item.category_name,
            SubcategorieName: item.subcategory_name,
            status: item.category_status === 1 ? 'Active' : 'Inactive',
            featured: 'Yes',
            SubcategoryId: item.subcat_id,
          }));
          setData(formattedData);
          setFilteredData(formattedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle search
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    setFilteredData(
      data.filter((item) =>
        item.categorieName.toLowerCase().includes(lowercasedQuery)
      )
    );
    setCurrentPage(1); // Reset to first page when search query changes
  }, [searchQuery, data]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to handle the header checkbox click
  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Toggle the selectAll state
    setSelectAll(newSelectAll);
    setFilteredData(
      filteredData.map((row) => ({ ...row, selected: newSelectAll }))
    ); // Update all rows with the new selection state
  };

  // Function to handle individual row checkbox click
  const handleRowCheckboxChange = (index) => {
    const updatedData = filteredData.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setFilteredData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected)); // If all checkboxes are selected, set selectAll to true
  };

  const toggleStatus = () => {
    setNewCategory({
      ...newCategory,
      status: newCategory.status === 'Active' ? 'Inactive' : 'Active',
    });
  };

  const handleAddSubcategory = async () => {
    const payload = {
      cat_id: newCategory.cat_id,
      subcategory_name: newCategory.subCategory,
      subcategory_order: newCategory.order,
      subcategory_status: newCategory.status === 'Active' ? 1 : 0,
      category_name: newCategory.name,
      category_status: 1, // Assuming the category is always active
      menu_order: 1, // Placeholder for menu order
    };

    try {
      const response = await api.post('/admin/subcategory', payload);
      if (response.data.status === 'success') {
        alert('Subcategory added successfully!');
      } else {
        alert('Failed to add subcategory.');
      }
    } catch (error) {
      console.error('Error adding subcategory:', error);
    }

    // Reset the form
    setNewCategory({
      cat_id: null,
      name: '',
      subCategory: '',
      order: '',
      status: 'Inactive',
    });
    setShowModal(false);
  };

  // Handle Category Selection (Does not auto-fill subcategory)
  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category.category_name === e.target.value
    );
    setNewCategory({
      ...newCategory,
      name: selectedCategory.category_name, // Set category name
      cat_id: selectedCategory.cat_id, // Set category ID
    });
  };

  const openEditModal = async (SubcategoryId) => {
    console.log('Selected Subcategory ID:', SubcategoryId);
    try {
      // Fetch subcategory details by ID
      const response = await api.get(`/admin/subcategory/${SubcategoryId}`);
      console.log('API Response:', response.data);

      // Adjust based on API response structure
      const subcategory = response.data.subcategory || response.data;

      if (!subcategory || !subcategory.cat_id) {
        throw new Error('Invalid API response: Missing subcategory data');
      }

      // Populate the modal with fetched data
      setSelectedCategoryIndex(SubcategoryId);
      setNewCategory({
        cat_id: subcategory.cat_id,
        subcategory_name: subcategory.subcategory_name,
        menuOrder: subcategory.subcategory_order,
        status: subcategory.subcategory_status === 1 ? 'Active' : 'Inactive',
        category_name: subcategory.category_name,
      });

      setShowEditModal(true);
    } catch (error) {
      console.error('Error fetching subcategory details:', error);
    }
  };

  const handleEditCategory = async () => {
    const updatedData = {
      cat_id: newCategory.cat_id,
      subcategory_name: newCategory.subcategory_name,
      subcategory_order: newCategory.menuOrder,
      subcategory_status: newCategory.status === 'Active' ? 1 : 0,
      category_name: newCategory.category_name,
      category_status: 1, // Assuming category is active
      menu_order: 1, // Placeholder value
    };

    try {
      const response = await api.post(`/admin/subcategory/`, updatedData); // Update API call
      if (response.data.status === 'success') {
        console.log('Subcategory updated successfully:', response.data);
        alert('Subcategory updated successfully!');
      } else {
        alert('Failed to update subcategory.');
      }
    } catch (error) {
      console.error('Error updating subcategory:', error);
    }

    // Close modal and reset state
    setShowEditModal(false);
    setNewCategory({});
  };

  // Ctogory api call
  // Get All Data  api
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/admin/categories'); // Fetch categories
        if (response.data.status === 'success') {
          setCategories(response.data.data); // Store categories in state
          console.log(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">SubCategories</h2>
          <p className="text-gray-500"></p>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowModal(true)}
            className="bg-Select_text hover:bg-Select_text2 text-white font-semibold h-min p-3 text-sm rounded-lg"
          >
            <span className="flex items-center gap-2">
              <IoMdAddCircleOutline /> Add New Categories
            </span>
          </button>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw] ">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full  border-collapse border">
            <thead className="border-b">
              <th className="border-b">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                />
              </th>

              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Categories
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Sub Categories
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Status
              </th>
              <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                Action
              </th>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>

                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.categorieName}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4 ">
                    <div className="">
                      <span className="">{item.SubcategorieName}</span>
                    </div>
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <span className="px-5 py-3 rounded-lg bg-green-100 border border-green-600 text-green-600">
                      {item.status}
                    </span>
                  </td>

                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className="flex gap-2 justify-start flex-wrap">
                      <button
                        onClick={() => openEditModal(item.SubcategoryId)}
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <MdOutlineEdit />
                      </button>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#092C4C] hover:bg-[#092C4C] hover:text-white`}
                      >
                        <FaList />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between px-8 py-2 border-b border-r border-l">
        <p className="text-gray-600">
          Showing {currentPageData.length} of {data.length} Results
        </p>
        <div className="flex gap-1">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
          >
            <FaAngleLeft />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`bg-gray-200 hover:bg-orange-600 text-gray-800 font-bold py-2 px-4 rounded-full ${
                currentPage === index + 1 ? 'bg-orange-400 text-white' : ''
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full"
          >
            <FaAngleRight />
          </button>
        </div>
      </div>

      {/* Add Category Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className="flex items-center justify-between py-2">
              <h2 className="text-lg font-bold">Add New Subcategory</h2>
              <button onClick={() => setShowModal(false)} className="font-bold">
                X
              </button>
            </div>

            {/* Category Dropdown */}
            <label htmlFor="Category" className="font-semibold">
              Category
            </label>
            <select
              id="Category"
              value={newCategory.category_name}
              onChange={handleCategoryChange} // Handle category selection
              className="w-full mb-4 p-2 border rounded"
            >
              <option value="" disabled>
                Select Category
              </option>
              {categories.map((category) => (
                <option key={category.cat_id} value={category.category_name}>
                  {category.category_name}
                </option>
              ))}
            </select>

            {/* Subcategory Name */}
            <label htmlFor="newSubCategory" className="font-semibold">
              Subcategory Name
            </label>
            <input
              type="text"
              id="newSubCategory"
              value={newCategory.subCategory}
              onChange={(e) =>
                setNewCategory({ ...newCategory, subCategory: e.target.value })
              }
              placeholder="Enter subcategory name"
              className="w-full mb-4 p-2 border rounded"
            />

            {/* Subcategory Order */}
            <label htmlFor="order" className="font-semibold">
              Subcategory Order
            </label>
            <input
              type="number"
              id="order"
              value={newCategory.order}
              onChange={(e) =>
                setNewCategory({ ...newCategory, order: e.target.value })
              }
              placeholder="Enter order"
              className="w-full mb-4 p-2 border rounded"
            />

            {/* Status Toggle */}
            <div>
              <h1 className="font-semibold">Status</h1>
              <button
                onClick={() =>
                  setNewCategory({
                    ...newCategory,
                    status:
                      newCategory.status === 'Active' ? 'Inactive' : 'Active',
                  })
                }
                className={`px-4 py-2 rounded-md mb-4 ${
                  newCategory.status === 'Active'
                    ? 'bg-green-500'
                    : 'bg-red-500'
                } text-white`}
              >
                {newCategory.status}
              </button>
            </div>

            {/* Add Button */}
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleAddSubcategory}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Category Modal */}
      {showEditModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <div className="flex items-center justify-between py-2">
              <h2 className="text-lg font-bold mb-4">Edit Subcategory</h2>
              <button
                onClick={() => setShowEditModal(false)}
                className="font-bold"
              >
                X
              </button>
            </div>

            {/* Category Dropdown */}
            <label htmlFor="Category" className="font-semibold">
              Category
            </label>
            <select
              id="Category"
              value={newCategory.category_name}
              onChange={(e) =>
                setNewCategory({
                  ...newCategory,
                  category_name: e.target.value,
                })
              }
              className="w-full mb-4 p-2 border rounded"
            >
              <option value="" disabled>
                Select Category
              </option>
              {categories.map((category) => (
                <option key={category.cat_id} value={category.category_name}>
                  {category.category_name}
                </option>
              ))}
            </select>

            {/* Subcategory Name */}
            <label htmlFor="newCategory" className="font-semibold">
              Subcategory Name
            </label>
            <input
              type="text"
              id="newCategory"
              value={newCategory.subcategory_name}
              onChange={(e) =>
                setNewCategory({
                  ...newCategory,
                  subcategory_name: e.target.value,
                })
              }
              placeholder="Enter subcategory name"
              className="w-full mb-4 p-2 border rounded"
            />

            {/* Subcategory Order */}
            <label htmlFor="menuOrder" className="font-semibold">
              Subcategory Order
            </label>
            <input
              type="number"
              id="menuOrder"
              value={newCategory.menuOrder}
              onChange={(e) =>
                setNewCategory({ ...newCategory, menuOrder: e.target.value })
              }
              placeholder="Enter order"
              className="w-full mb-4 p-2 border rounded"
            />

            {/* Status Toggle */}
            <h1 className="font-semibold">Status</h1>
            <button
              onClick={() =>
                setNewCategory({
                  ...newCategory,
                  status:
                    newCategory.status === 'Active' ? 'Inactive' : 'Active',
                })
              }
              className={`px-4 py-2 rounded-md mb-4 ${
                newCategory.status === 'Active' ? 'bg-green-500' : 'bg-red-500'
              } text-white`}
            >
              {newCategory.status}
            </button>

            {/* Save Button */}
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleEditCategory}
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SubCategories;
