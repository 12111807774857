import React, { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { PiEyeSlash } from 'react-icons/pi';
import { MdOutlineEdit } from 'react-icons/md';
import api from '../../../utils/api';

function AutomaticGateways() {
  const [data, setData] = useState([]); // Data for gateways
  const [selectAll, setSelectAll] = useState(false); // Manage select-all checkbox state
  const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const rowsPerPage = 10; // Rows per page

  // Fetch data from API response
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/admin/gateway-automatic');
        if (response.success) {
          const formattedData = response.data.map((item) => ({
            selected: false,
            gateway: item.name || 'Unknown Gateway',
            supportedCurrency: Object.keys(
              JSON.parse(item.supported_currencies || '{}')
            ).length.toString(),
            enabledCurrency: Object.keys(
              JSON.parse(item.gateway_parameters || '{}')
            ).length.toString(),
            status: item.status === 1 ? 'Enabled' : 'Disabled',
          }));
          setData(formattedData);
          console.log('formattedData', formattedData);
        } else {
          console.error('API call failed:', response.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // Handle select-all checkbox
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((row) => ({ ...row, selected: newSelectAll })));
  };

  // Handle individual row selection
  const handleRowCheckboxChange = (index) => {
    const updatedData = data.map((row, rowIndex) =>
      rowIndex === index ? { ...row, selected: !row.selected } : row
    );
    setData(updatedData);
    setSelectAll(updatedData.every((row) => row.selected));
  };

  // Filter data based on the search term
  const filteredData = data.filter((item) =>
    item.gateway.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <>
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Automatic Gateways</h2>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 w-[80vw]">
        <div className="flex justify-between items-center p-4 border-t border-l border-r">
          <div className="w-72 flex items-center gap-2 border px-4 rounded-lg">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 rounded-md outline-none"
              placeholder="Search..."
            />
            <IoSearch className="text-2xl" />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table-auto w-full border-collapse border">
            <thead className="border-b">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                  />
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Gateway
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Supported Currency
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Enabled Currency
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Status
                </th>
                <th className="px-4 py-4 text-black text-start font-bold uppercase text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 border-b">
                  <td className="px-4 py-3 text-md text-center whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleRowCheckboxChange(index)}
                      className="h-4 w-4 text-Select_text border-gray-300 rounded focus:ring-Select_text"
                    />
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.gateway}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.supportedCurrency}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    {item.enabledCurrency}
                  </td>
                  <td className="px-2 py-3 text-md text-start pl-4">
                    <span
                      className={`px-5 py-3 rounded-lg ${
                        item.status === 'Enabled'
                          ? 'bg-green-100 border border-green-600 text-green-600'
                          : 'bg-red-100 border border-red-600 text-red-600'
                      }`}
                    >
                      {item.status}
                    </span>
                  </td>
                  <td className="px-2 py-3 text-sm text-start pl-4">
                    <div className="flex gap-2 justify-start flex-wrap">
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#288EC7] hover:bg-[#288EC7] hover:text-white`}
                      >
                        <MdOutlineEdit />
                      </button>
                      <button
                        className={`px-3 py-3 rounded-lg text-xl font-bold bg-gray-50 text-[#FF0000] hover:bg-[#FF0000] hover:text-white`}
                      >
                        <PiEyeSlash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md"
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default AutomaticGateways;
