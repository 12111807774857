import React, { useState } from 'react';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { GoShieldCheck } from 'react-icons/go';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import api from '../../utils/api';

function Clear() {
  const handleClearClick = async () => {
    try {
      const response = await api.post('/admin/clear-cache'); // Replace with your actual API call
      if (response.data.success) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
      } else {
        toast.error('Failed to clear cache. Please try again.', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error('An error occurred while clearing the cache.', {
        autoClose: 3000,
      });
      console.error('API error:', error);
    }
  };

  return (
    <>
      <ToastContainer />
      <header className="flex justify-between px-6 py-2 mt-2">
        <div>
          <h2 className="text-xl font-bold">Clear System Cache</h2>
          <p className="text-gray-500"></p>
        </div>
      </header>
      <div className="mx-auto mt-2 p-4 md:w-3/5 w-full">
        <div className="bg-white shadow-md rounded-md p-6">
          <ul className="text-2xl ml-6">
            <li className="flex gap-2 items-center">
              <span>
                <IoCheckmarkDoneSharp />
              </span>
              Compiled views will be cleared
            </li>
            <li className="flex gap-2 items-center">
              <span>
                <IoCheckmarkDoneSharp />
              </span>
              Application cache will be cleared
            </li>
            <li className="flex gap-2 items-center">
              <span>
                <IoCheckmarkDoneSharp />
              </span>
              Route cache will be cleared
            </li>
            <li className="flex gap-2 items-center">
              <span>
                <IoCheckmarkDoneSharp />
              </span>
              Configuration cache will be cleared
            </li>
            <li className="flex gap-2 items-center">
              <span>
                <IoCheckmarkDoneSharp />
              </span>
              Compiled services and packages files will be removed
            </li>
            <li className="flex gap-2 items-center">
              <span>
                <IoCheckmarkDoneSharp />
              </span>
              Caches will be cleared
            </li>
          </ul>

          <button
            onClick={handleClearClick}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Click to clear
          </button>
        </div>
      </div>
    </>
  );
}

export default Clear;
