import React, { useEffect, useState } from 'react';
import api from '../../utils/api'; // Adjust the import path as per your project structure
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SitemapXML = () => {
  const [sitemapXml, setSitemapXml] = useState('');

  // Fetch Sitemap XML from the API
  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const response = await api.get('/admin/sitemaps');
        if (response.data && response.data.success && response.data.data) {
          setSitemapXml(JSON.parse(response.data.data).Conetnet);
          toast.success('Sitemap XML fetched successfully!');
        } else {
          throw new Error('Failed to fetch sitemap XML.');
        }
      } catch (error) {
        console.error('Error fetching sitemap XML:', error);
        toast.error('Failed to fetch sitemap XML. Please try again.');
      }
    };

    fetchSitemap();
  }, []);

  const handleChange = (e) => {
    setSitemapXml(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      sitemap: JSON.stringify({ Conetnet: sitemapXml }),
    };

    try {
      const response = await api.post('/admin/sitemaps', requestBody);
      if (response.data && response.data.success) {
        toast.success('Sitemap XML updated successfully!');
      } else {
        throw new Error('Failed to update sitemap XML.');
      }
    } catch (error) {
      console.error('Error updating sitemap XML:', error);
      toast.error('Failed to update sitemap XML. Please try again.');
    }
  };

  // Function to calculate line numbers based on textarea value
  const getLineNumbers = () => {
    return sitemapXml.split('\n').map((_, index) => index + 1).join('\n');
  };

  return (
    <>
      <ToastContainer />
      <header className='flex justify-between px-6 py-2 mt-2'>
        <div>
          <h2 className="text-xl font-bold">Sitemap XML</h2>
        </div>
      </header>

      <div className="mt-4 gap-4 px-8">
        <h1 className="text-xl font-bold mb-4">Insert Sitemap XML</h1>
        <form onSubmit={handleSubmit} className="relative">
          <div className="flex">
            {/* Line Numbers */}
            <div
              className="bg-black text-gray-400 rounded-l-lg p-2 text-right select-none"
              style={{ minWidth: '50px' }}
            >
              <pre>{getLineNumbers()}</pre>
            </div>

            {/* Sitemap Editor */}
            <textarea
              value={sitemapXml}
              onChange={handleChange}
              className="w-full min-h-96 p-2 bg-black rounded-r-lg text-white border-none focus:outline-none resize-none"
              placeholder="Insert your Sitemap XML content here..."
            />
          </div>

          <button
            type="submit"
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default SitemapXML;
